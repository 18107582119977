import React, {useState, useEffect, useContext} from "react"

import "./register.css"

import useExecute from "hooks/useExecute"
import { AxiosError, AxiosResponse } from "axios"
import TextField from "components/inputs/textfieldUnderline/textfieldUnderline"
import PasswordField from "components/inputs/passwordfieldUnderline/passwordfieldUnderline"
import IClient from "interfaces/IClient"
import CheckBox from "components/inputs/checkbox/checkbox"
import { apiCaller } from "api/apiCaller"
import { AuthUserContext } from "../../authentication"

export default function Register(props: {onWaitResponse: Function}){

    const authUserContext = useContext(AuthUserContext)


    const [name,            setName           ] = useState<string>("")
    const [surname,         setSurname        ] = useState<string>("")
    const [email,           setEmail          ] = useState<string>("")
    const [cpf,             setCpf            ] = useState<string>("")
    const [password,        setPassword       ] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [error,           setError          ] = useState<string>("")

    useExecute(4.6, error, handleResetError)

    function handleNameChange(value: string){
        setName(value)
    }

    function handleSurnameChange(value: string){
        setSurname(value)
    }

    function handleEmailChange(value: string){
        setEmail(value)
    }
    
    function handlePasswordChange(value: string){
        setPassword(value)
    }

    function handleCpfChange(value: string){
        if (/^\d+$/.test(value) === true || value === "") {
            setCpf(value)
        }
    }

    function handleConfirmPasswordChange(value: string){
        setConfirmPassword(value)
    }
    
    function handleEULAAgreement(){
        
    }

    function handleResetError(){
        setError("")
    }
    
    function handleLogin(){
        authUserContext?.navigate("login")
    }

    function handleRegister(){
        let errorMessage: string = "campos inválidos: "
        let error = false

        if (name === ""){
            error = true
            errorMessage = errorMessage.concat("nome, ")
        }
        if (surname === ""){
            error = true
            errorMessage = errorMessage.concat("sobrenome, ")
        }
        if (email === ""){
            error = true
            errorMessage = errorMessage.concat("email, ")
        }
        if (cpf === ""){
            error = true
            errorMessage = errorMessage.concat("cpf, ")
        }
        if (password === ""){
            error = true
            errorMessage = errorMessage.concat("senha, ")
        } 
        if (confirmPassword === ""){
            error = true
            errorMessage = errorMessage.concat("confirmação de senha, ")
        }
        else {
            let triggerAnimation: boolean = true
    
            setTimeout(() => {
                if (triggerAnimation){
                    props.onWaitResponse(true, "register")
                }
            }, 100)
            apiCaller.post("/auth/signup", {
                name:     name,
                surname:  surname,
                password: password,
                cpf:      cpf,
                email:    email
            }).then((response: AxiosResponse) => {
                if (triggerAnimation) {
                    props.onWaitResponse(false, "register")
                    triggerAnimation = false
    
                    authUserContext?.setUser({
                        email: email,
                        password: password
                    })
                    
                    authUserContext?.navigate("emailConfirm")
                } 
            }).catch((error: AxiosError) => {
                if (triggerAnimation) {
                    props.onWaitResponse(false, "register")
                    triggerAnimation = false
                    setError("verifique os dados e tente novamente")
                }
            })
        }

        if (error){
            let lastComma = errorMessage.lastIndexOf(",")
            errorMessage = errorMessage.substring(0, lastComma)
            setError(errorMessage)
        }
    }

    return (
        <div id="cart-authentication-register-main">
            <div id="cart-authentication-register-field-container">
                <div className="cart-authentication-register-field-container">
                    <div className="cart-authentication-register-field">
                        <TextField 
                            label="Nome"
                            onValueChange={handleNameChange}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div className="cart-authentication-register-field">
                        <TextField 
                            label="Sobrenome"
                            onValueChange={handleSurnameChange}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div className="cart-authentication-register-field">
                        <TextField
                            label="Email"
                            onValueChange={handleEmailChange}
                            onSelected={handleResetError}
                        />
                    </div>
                </div>
                <div className="cart-authentication-register-field-container">
                    <div className="cart-authentication-register-field">
                        <PasswordField 
                            label="Senha"
                            onValueChange={handlePasswordChange}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div className="cart-authentication-register-field">
                        <PasswordField 
                            label="Confirmar Senha"
                            onValueChange={handleConfirmPasswordChange}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div className="cart-authentication-register-field">
                        <TextField 
                            label="CPF"
                            onValueChange={handleCpfChange}
                            onSelected={handleResetError}
                            numeric={true}
                        />
                    </div>
                    
                </div>
            </div>
            <div id="cart-authentication-register-warning">
                {
                    error !== "" &&
                    (
                        <p id="cart-authentication-register-warning-txt">
                            {error}
                        </p>
                    )
                }
            </div>
            <div id="cart-authentication-register-btn-container">
                <div 
                    id="cart-authentication-register-register-btn"
                    onClick={handleRegister}
                >
                    <p id="cart-authentication-register-register-btn-txt">
                        Registrar
                    </p>
                </div>
                <p id="cart-authentication-register-login-btn" onClick={handleLogin}>
                    Login
                </p>
            </div>
        </div>
    )
}