import React from "react"

import "./variation.css"

import { IProductVariation } from "interfaces/product/IProduct"

export default function Option(props: {data: IProductVariation}){
    const getColorHex = (color: string) => {
        if (color.includes('#')) {
            return color;
        } else{
            return `#${color}`;
        }
    }

    return (
        <>
            <li
                id="admin-product-list-product-variation-selector-variation-main" 
                style={{backgroundColor: `${getColorHex(props.data.color)}`}}
            >
            </li>
        </>
    )
}