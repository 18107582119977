import axios from "axios"

export default async function calculaFrete(cep: string) {
    try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { uf } = response.data;

        if (["MG", "ES", "SP", "RJ", "SC", "PR", "RS"].includes(uf)) {
            return 19.90;
        }

        return 29.99;
    } catch (error) {
        console.error('api error:', error);
        throw error
    }
}