import React, {useContext} from "react"

import "./banner.css"

import { BannerConfigurationContext } from "pages/admin/settings/components/mainPage/mainPage"
import IBanner from "interfaces/IBanner"
import { getBannerImageUrl } from "misc/imageUrls"

export default function Banner(props: {data: IBanner}){
    
    const bannerConfigurationContext = useContext(BannerConfigurationContext)
    
    function handleShowDetails(){
        bannerConfigurationContext?.setSelectedBanner(props.data)
        bannerConfigurationContext?.navigate("detail")
    }   

    return (
        <li id="admin-settings-catalog-banners-list-element" onClick={handleShowDetails}>
            <img 
                id="admin-settings-catalog-banners-list-element-img"
                src={getBannerImageUrl(props.data.filename)} 
                alt="" 
                draggable={false}
            />
        </li>
    )
}