import React, {useState, useEffect, useContext} from "react"

import "./variation.css"

import CheckBox from "components/inputs/checkbox/checkbox"
import TextInput from "components/textInput/textInput"
import ColorInput from "components/inputs/colorInput/colorInput"
import SizeList from "./components/sizeList/sizeList"
import ImageList from "./components/imageList/imageList"
import { ProductContext } from "pages/admin/product/product/product" 

import { IProductVariation } from "interfaces/product/IProduct"

export default function Variation(props: {data: IProductVariation}){

    const productContext = useContext(ProductContext)
    
    const [enableVariationCheckboxDisable, setEnableVariationCheckboxDisable] = useState<boolean>(true)

    useEffect(() => {
        let haveEnabledSize = false

        props.data.sizes.forEach((size) => {
            if (size.enabled){
                haveEnabledSize = true
            }
        })

        setEnableVariationCheckboxDisable(false)
        
        const { name, color, price, images, promotionEnabled, promotionPrice, sizes } = props.data;

        if (
            !name ||
            !color ||
            price === 0 ||
            images.length === 0 ||
            (promotionEnabled && promotionPrice === 0) ||
            sizes.length === 0 ||
            haveEnabledSize === false
        ) {
            setEnableVariationCheckboxDisable(true);
        }

    }, [props.data]);

    function handleChange(field: string, value: string | boolean | number){
        if (productContext){
            productContext.setProduct((prev) => {
                const variationsCopy = [...prev.variations];
                variationsCopy[productContext.variationIndex] = {
                    ...variationsCopy[productContext.variationIndex],
                    [field]: value,
                };
                return {
                    ...prev,
                    variations: variationsCopy
                };
            });
        }
    }

    function handleCheckboxClick(){
        if (productContext) {
            if (enableVariationCheckboxDisable){
                productContext.setError("Erro: para habilitar uma variação preencha todos os campos")
            }
        }
    }

    function handleDelete(){
        if (productContext) {
            productContext.setVariationIndex(0)

            productContext.setProduct((prevProduct) => {
                const updatedVariations = [...prevProduct!.variations];
                updatedVariations.splice(productContext.variationIndex, 1);
                return {
                    ...prevProduct!,
                    variations: updatedVariations,
                };
            });
        }
    }

    return (
        <div id="admin-product-variation-content">
            <div id="admin-product-variation-content-primary">
                <div className="admin-product-variation-field">
                    <TextInput
                        label="Nome"
                        onValueChange={(value: string) => {handleChange("name", value)}}
                        value={props.data.name}
                    />
                </div>
                <div className="admin-product-variation-field">
                    {/* <TextInput
                        label="Cor"
                        onValueChange={(value: string) => {handleChange("color", value)}}
                        value={props.data.color}
                    /> */}
                    <ColorInput
                        value={props.data.color}
                        label="cor"
                        onValueChange={(value: string) => {handleChange("color", value)}}
                    />
                </div>
                <div className="admin-product-variation-field">
                    <TextInput
                        monetary={true}
                        numeric={true}
                        label="Preço"
                        onValueChange={(value: number) => {handleChange("price", value)}}
                        value={`${props.data.price}`}
                    />
                </div>
                <div className="admin-product-variation-field">
                    {
                        props.data.promotionEnabled &&
                        (
                            <TextInput
                                monetary={true}
                                numeric={true}
                                label="Preço Promocional"
                                onValueChange={(value: number) => {handleChange("promotionPrice", value)}}
                                value={`${props.data.promotionPrice}`}
                            />
                        )
                    }
                </div>
                <div id="admin-product-variation-checkbox-field1">
                    <CheckBox
                        text="Habilitar promoção"
                        onValueChange={(value: boolean) => {handleChange("promotionEnabled", value)}}
                        value={props.data.promotionEnabled}
                    />
                </div>
                <div id="admin-product-variation-checkbox-field2">
                    <CheckBox
                        text="Habilitar Variação"
                        onValueChange={(value: boolean) => {handleChange("enabled", value)}}
                        value={props.data.enabled}
                        disabled={enableVariationCheckboxDisable}
                        onClick={handleCheckboxClick}
                    />
                </div>
                <div id="admin-product-variation-delete">
                    <p id="admin-product-variation-delete-btn" onClick={handleDelete}>
                        Deletar Variação
                    </p>
                </div>
            </div>
            <div id="admin-product-variation-content-secondary">
                <SizeList
                    data={props.data.sizes}
                />
            </div>
            <div id="admin-product-variation-content-terciary">
                <ImageList
                    data={props.data.images}
                />
            </div>
        </div>
    )
}