import React, {useState, useEffect, useContext} from "react"

import "./details.css"



import OrderProductCard from "pages/admin/orders/components/orderProductCard/orderProductCard"
import TimeDateParser   from "misc/timeDateParser"
import IOrder           from "interfaces/order/IOrder"
import { IPaymentCreditCard, IPaymentPix, IPayment } from "interfaces/order/IPayment"
import CloseIcon from "assets/icons/close-button-black.png"
import PacketIcon from "assets/icons/packet-box.png"
import TextInput from "components/textInput/textInput"
import { AuthContext } from "context/authProvider"
import { AxiosError, AxiosResponse } from "axios"
import { apiCaller } from "api/apiCaller"

export default function Details(props: {data: any, onCancelOrder: Function, onForwardOrder: Function}){

    const [renderTrackCodeModal, setRenderTrackCodeModal] = useState<boolean>(false)

    const authContext = useContext(AuthContext)

    const [cpf, setCpf] = useState()
    const [email, setEmail] = useState()
    const [name, setName] = useState()
    const [surname, setSurname] = useState()

    const [address1, setAddress1] = useState()
    const [address2, setAddress2] = useState()
    const [city, setCity] = useState()
    const [complement, setComplement] = useState()
    const [postalCode, setPostalCode] = useState()
    const [state, setState] = useState()
    const [unitNumber, setUnitNumber] = useState()

    const [trackCode, setTrackCode] = useState<string>()

    useEffect(() => {
        console.log(props.data.boughtProducts)

        const productIds = props.data.boughtProducts.map((product: any) => {
            return product.id
        })

        const token = `Bearer ${authContext?.getToken()}`
        
        apiCaller.post("/products/find-ids",
        {
            ids: productIds
        }, 
        {
            headers: {
                "Authorization": token
            }
        }).then((response: AxiosResponse) => {
            console.log(response.data)
        }).catch((error: AxiosError) => {
            console.log(error)
        })

        apiCaller.get(`/accounts/${props.data.userId}`,{
            headers: {
                "Authorization": token
            }
        }).then((response: AxiosResponse) => {
            setCpf(response.data.data.cpf)
            setEmail(response.data.data.email)
            setName(response.data.data.name)
            setSurname(response.data.data.surname)
        }).catch((error: AxiosError) => {
            console.log(error)
        })

        setAddress1(props.data.address.address1);
        setAddress2(props.data.address.address2);
        setCity(props.data.address.city);
        setComplement(props.data.address.complement);
        setPostalCode(props.data.address.postalCode);
        setState(props.data.address.state);
        setUnitNumber(props.data.address.unitNumber);
    },[props.data.id])

    function handleShip(){
        if (trackCode){
            props.onForwardOrder(props.data.id, trackCode)
        }
    }

    function handleCancelOrder(){
        props.onCancelOrder(props.data.id)
    }

    function handleTrackCodeChange(value: string){
        setTrackCode(value)
    }

    function trackCodeModalController(value: boolean){
        setRenderTrackCodeModal(value)
    }

    function parsePaymentMethod(payment: IPayment): string {
        
        if ("company" in payment.data) {

            return `cartão de credito ${payment.data.company} ${payment.data.instalmentQuantity}x R$ ${payment.data.instalentValue}`

        } else {
            return `pix 1x R$ ${payment.data.total}`
        }
    }   

    function parseTotal(payment: IPayment): string {
        return `R$ ${payment.data.total}`
    }   

    return (
        <>
            <div id="admin-ready-orders-order-detail-main">
                <div id="admin-ready-orders-order-detail-order-header">
                    <div id="admin-ready-orders-order-detail-order-header-identifier">
                        <p id="admin-ready-orders-order-detail-order-header-identifier-text1">
                            Pedido
                        </p>
                        <p id="admin-ready-orders-order-detail-order-header-identifier-text2">
                            {props.data.id.split("-")[0]}
                        </p>
                    </div>
                    <div id="admin-ready-orders-order-detail-order-header-cancel">
                        <p id="admin-ready-orders-order-detail-order-header-cancel-btn2" onClick={handleCancelOrder}>
                            Cancelar pedido
                        </p>
                    </div>
                </div>
                <div id="admin-ready-orders-order-detail-body">
                    <div id="admin-ready-orders-order-detail-body-data">
                        <div id="admin-ready-orders-order-detail-body-data-payment">
                            <div id="admin-ready-orders-order-detail-body-data-payment-title">
                                <p id="admin-ready-orders-order-detail-body-data-payment-title-text">
                                    Dados do pedido
                                </p>
                            </div>
                            <div className="admin-ready-orders-order-detail-body-data-payment-client-fields">
                                <p className="text-label">Nome:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    {`${name} ${surname}`}
                                </p>
                                <p className="text-label">CPF:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    {cpf}
                                </p>
                            </div>
                            <div className="admin-ready-orders-order-detail-body-data-payment-client-fields">
                                <p className="text-label">E-mail:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    {email}
                                </p>
                            </div>
                            {/* <div className="admin-ready-orders-order-detail-body-data-payment-client-fields">
                                <p className="text-label">Pagamento:</p>
                                <div id="admin-ready-orders-order-detail-body-data-payment-method-detail1">
                                    {
                                        props.data.payment &&
                                        (   
                                            <p id="admin-ready-orders-order-detail-body-data-payment-method-detail1-text">
                                                {parsePaymentMethod(props.data.payment)}
                                            </p>
                                        )
                                    }
                                </div>
                            </div> */}
                            <div className="admin-ready-orders-order-detail-body-data-payment-client-fields">
                                <p className="text-label">Valor total do pedido:</p>
                                    <p className="admin-ready-orders-order-info-text">
                                        {`R$ ${(props.data.productPrice + props.data.shipmentPrice).toFixed(2).replace(".", ",")}`}
                                    </p>
                            </div>
                            <div className="admin-ready-orders-order-detail-body-data-payment-client-fields">
                                <p className="text-label">Data do pedido:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    {props.data.createdAt.split(" ")[0]}      
                                </p>
                                {/* {
                                    props.data.timeStamp &&
                                    (
                                        <p className="admin-ready-orders-order-info-text">
                                            {
                                                TimeDateParser.parseDate(
                                                    props.data.timeStamp?.date.day,
                                                    props.data.timeStamp?.date.month,
                                                    props.data.timeStamp?.date.year
                                                )
                                            }
                                        </p>
                                    )
                                } */}
                            </div>
                            <div className="admin-ready-orders-order-detail-body-data-payment-client-fields">
                                <p className="text-label">Horário:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    {props.data.createdAt.split(" ")[1]}     
                                </p>
                                {/* {
                                    props.data.timeStamp &&
                                    (
                                        <p className="admin-ready-orders-order-info-text">
                                            {
                                                TimeDateParser.parseTime(
                                                    props.data.timeStamp.time.hour,
                                                    props.data.timeStamp.time.minute
                                                )
                                            }
                                        </p>
                                    )
                                } */}
                            </div>
                            <div className="admin-ready-orders-order-detail-body-data-payment-client-fields">
                                <p className="text-label">Valor do frete:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    R$ {`${props.data.shipmentPrice.toFixed(2)}`.replace(".", ",")}
                                </p>
                            </div>
                        </div>
                        <div id="admin-ready-orders-order-detail-body-data-shipment">
                            <div id="admin-ready-orders-order-detail-body-data-shipment-title">
                                <p id="admin-ready-orders-order-detail-body-data-shipment-title-text">
                                    Dados da entrega
                                </p>
                            </div>
                            <div className="admin-ready-orders-order-detail-body-data-shipment-client-fields">
                                <p className="text-label">Logradouro:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    {address1}
                                </p>
                            </div>
                            <div className="admin-ready-orders-order-detail-body-data-shipment-client-fields">
                                <p className="text-label">
                                    Numero:
                                </p>
                                <p className="admin-ready-orders-order-info-text">
                                    {unitNumber}
                                </p>
                                {
                                    complement &&
                                    (
                                        <>
                                            <p className="text-label">
                                                Complemento:
                                            </p>
                                            <p className="admin-ready-orders-order-info-text">
                                                {complement}
                                            </p>
                                        </>
                                    )
                                }
                            </div>
                            <div className="admin-ready-orders-order-detail-body-data-shipment-client-fields">
                                <p className="text-label">Cidade:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    {city}
                                </p>
                                <p className="text-label">Bairro:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    {address2}
                                </p>
                            </div>
                            <div className="admin-ready-orders-order-detail-body-data-shipment-client-fields">
                                <p className="text-label">Estado:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    {state}
                                </p>
                            </div>
                            <div className="admin-ready-orders-order-detail-body-data-shipment-client-fields">
                                <p className="text-label">CEP:</p>
                                <p className="admin-ready-orders-order-info-text">
                                    {postalCode}
                                </p>
                            </div>
                        </div>
                        <div id="admin-ready-orders-order-detail-body-data-trial">
                            <div 
                                id="admin-ready-orders-order-detail-body-data-trial-btn" 
                                onClick={() => {trackCodeModalController(true)}}
                            >
                                <p id="admin-ready-orders-order-detail-body-data-trial-btn-text">
                                    Despachar Pedido
                                </p>
                            </div>
                        </div>
                    </div>
                    <ul id="admin-ready-orders-order-detail-body-products">
                        {
                            props.data.boughtProducts &&
                            (
                                props.data.boughtProducts.map((product: any) => {
                                    return (
                                        <OrderProductCard
                                            key={product.id}
                                            product={product}
                                        />
                                    )
                                })
                            )
                        }
                    </ul>
                </div>
            </div>
            {
                renderTrackCodeModal &&
                (
                    <div id="admin-ready-orders-order-detail-ship-modal">
                        <div id="admin-ready-orders-order-detail-ship-modal-content">
                            <div id="admin-ready-orders-order-detail-ship-modal-content-exit">
                                <img 
                                    id="admin-ready-orders-order-detail-ship-modal-content-exit-btn"
                                    src={CloseIcon}
                                    alt="" 
                                    draggable={false}
                                    onClick={() => {trackCodeModalController(false)}}
                                />
                            </div>
                            <div id="admin-ready-orders-order-detail-ship-modal-content-image-container">
                                <img 
                                    id="admin-ready-orders-order-detail-ship-modal-content-image"
                                    src={PacketIcon} 
                                    alt="" 
                                    draggable={false}
                                />
                            </div>
                            <div id="admin-ready-orders-order-detail-ship-modal-content-input-container">
                                <div id="admin-ready-orders-order-detail-ship-modal-content-input">
                                    <TextInput
                                        onValueChange={(value: string) => {handleTrackCodeChange(value)}}
                                        label="Código de rastreio"
                                    />
                                </div>
                            </div>
                            <div id="admin-ready-orders-order-detail-ship-modal-content-btn-container">
                                <div id="admin-ready-orders-order-detail-ship-modal-content-btn" onClick={handleShip}>
                                    <p id="admin-ready-orders-order-detail-ship-modal-content-btn-txt">
                                        Adicionar código
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}