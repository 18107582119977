import React, {useEffect} from "react"

import "./orderProductCard.css"

import { getProductImageUrl } from "misc/imageUrls"


export default function OrderProductCard(props: {product: any}){

    return (
        <li id="order-product-card-main">
            <a 
                id="order-product-card-main-link" 
                href="/" target="_blank"
                draggable={false}
            >
                <div id="order-product-card-main-img-container">
                    <img 
                        id="order-product-card-main-img"
                        src={getProductImageUrl(props.product.product.variation.image.filename)} 
                        alt="" 
                        draggable={false}
                    />
                </div>
                <div id="order-product-card-main-data-container">
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Nome:
                        </p>
                        <p className="order-product-card-main-data-value">
                            {props.product.product.name}
                        </p>
                    </div>
                    {/* <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Referencia:
                        </p>
                      
                    </div>
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Quantidade:
                        </p>
                        <p className="order-product-card-main-data-value">
                        </p>
                    </div> */}
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Variação:
                        </p>
                        <p className="order-product-card-main-data-value">
                            {props.product.product.variation.name}
                        </p>
                    </div>
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Tamanho:
                        </p>
                        <p className="order-product-card-main-data-value">
                            {props.product.product.variation.size.size}
                        </p>
                    </div>
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Preço:
                        </p>
                        <p className="order-product-card-main-data-value">
                            {`R$ ${props.product.unitPrice.toFixed(2)}`.replace(".", ",")}
                        </p>
                    </div>
                </div>
            </a>
            
        </li>
    )
}