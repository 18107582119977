import React, {useState, useEffect} from "react"

import IBanner from "interfaces/IBanner"
import Banner from "./components/banner/banner"
import { apiCaller } from "api/apiCaller"
import { AxiosResponse, AxiosError } from "axios"

import "./bannerList.css"

export default function BannerList(){

    const [banners, setBanners] = useState<IBanner[]>([])

    useEffect(() => {
        apiCaller.get("/banners/", {

        }).then((response: AxiosResponse) => {
            setBanners(response.data.data)
            console.log(response.data.data)
        }).catch((error: AxiosError) => {
            console.log(error)
        })

    }, [])

    return (
        <ul id="admin-settings-catalog-banners-list">
            {
                banners.map((banner) => {
                    return(
                        <Banner key={banner.id} data={banner}/>
                    )
                })
            }
        </ul>
    )
}