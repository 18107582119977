import React, {useState, useEffect, useContext} from "react"

import "./shippingRulles.css"

import Header from "components/header/header"
import Footer from "components/footer/footer"

export default function ShippingRulles(){

    const [screenHeight, setScreenHeight] = useState<number>(0)

    useEffect(() => {
        const headerElement = window.document.getElementById("header-main")
        const productCategoryHeader = window.document.getElementById("shipping-rulles-template-header")
        
        if (headerElement && productCategoryHeader){
            const headerHeight = headerElement.getBoundingClientRect().height
            const pageTemplateHeaderHeight = productCategoryHeader.getBoundingClientRect().height

            let height = window.screen.availHeight
            height = height - (window.outerHeight - window.innerHeight)
            height = height - headerHeight
            height = height - pageTemplateHeaderHeight
            setScreenHeight(height)
        }
    },[window.innerHeight])


  
    return (
        <>  
            <Header/>
            <div id="shipping-rulles-template-main">
                <div id="shipping-rulles-template-content">
                    <div id="shipping-rulles-template-header">
                        <p id="shipping-rulles-header-txt">
                            Regras de Frete
                        </p>
                    </div>  
                    <div id="shipping-rulles-template-list" style={{minHeight: screenHeight}}>
                        <p className="shipping-rulles-text shipping-rulles-space shipping-rulles-text-category">
                            A Ebah! Moda Infantil oferece frete com valores fixos para garantir praticidade em suas compras. Confira abaixo as condições
                        </p>
                        <p className="shipping-rulles-text shipping-rulles-text-category">
                            Regiões Sul e Sudeste
                        </p>
                        <p className="shipping-rulles-text shipping-rulles-space">
                            O valor do frete é fixo em R$ 19,90 via PAC.
                        </p>
                        <p className="shipping-rulles-text shipping-rulles-text-category">
                            Demais Regiões do Brasil
                        </p>
                        <p className="shipping-rulles-text shipping-rulles-space">
                            O valor do frete é fixo em R$ 29,90 via PAC.
                        </p>
                        <p className="shipping-rulles-text shipping-rulles-text-category">
                            Florestal - MG
                        </p>
                        <p className="shipping-rulles-text shipping-rulles-space">
                            Para clientes de Florestal-MG, oferecemos entrega gratuita diretamente na cidade.
                        </p>
                        <p className="shipping-rulles-text shipping-rulles-text-category">
                            Arcos - MG
                        </p>
                        <p className="shipping-rulles-text shipping-rulles-space">
                            Se você mora em Arcos-MG, entre em contato conosco pelo WhatsApp para verificar a possibilidade de frete grátis.
                        </p>
                        <p className="shipping-rulles-text">
                            Esses valores serão aplicados automaticamente no fechamento do seu pedido, garantindo uma entrega segura e eficiente, independentemente do local.
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}