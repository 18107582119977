import React, {useContext} from "react"

import "./navigation.css"

import { IProductVariation} from "interfaces/product/IProduct"
import NavigationBtn        from "./components/productEditNavigationBtn"
import AddIcon from "assets/icons/plus-gray.png"
import { ProductContext } from "pages/admin/product/product/product" 

export default function Navigation(props: {data: IProductVariation[]}){

    const productContext = useContext(ProductContext)


    function handleCreateVariation(){
        if (productContext) {
            productContext.setProduct((prev) => ({
                ...prev,
                variations: [
                    ...prev.variations,
                    {
                        id: "",
                        name: "",
                        color: "",
                        price: 0,
                        enabled: false,
                        promotionEnabled: false,
                        promotionPrice: 0,
                        sizes: [],
                        images: []
                    }
                ]
            }))
        }
    }

    return (
        <ul id="admin-product-navigation-main">
            {
                props.data.map((variation, index) => {
                    return (
                        <NavigationBtn
                            key={index}
                            index={index}
                            variation={variation}
                        />
                    )
                })
            }
            <li 
                id="admin-product-navigation-add-btn"
                key={9999}
                onClick={handleCreateVariation}
            >
                <p id="admin-product-navigation-add-btn-txt">
                    Nova variação
                </p>
                <img 
                    id="admin-product-navigation-add-btn-img"
                    src={AddIcon}
                    alt="" 
                    draggable={false}
                />
            </li>
        </ul>
    )
}