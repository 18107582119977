import React, {useState, useEffect, useContext} from "react"

import './categoryPage.css';

import Dropdown            from "components/inputs/dropdownWithoutLabel/dropdownWithoutLabel"
import { ICategoryConfiguration, ISubcategoryConfiguration } from "interfaces/ICategoryConfiguration";
import CategoryPlaceholder from "assets/backgrounds/category-background-placeholder.svg"
import { apiCaller } from "api/apiCaller"
import { AxiosResponse } from "axios"
import { AuthContext } from "context/authProvider";
import { getCoverImageUrl } from "misc/imageUrls";
import IBanner from "interfaces/IBanner";


export default function CategoryPage(){

    const imageRatio = 3.84;

    const [isMounted, setIsMounted] = useState<boolean>(false)
    const [categories, setCategories] = useState<ICategoryConfiguration[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<ICategoryConfiguration>();
    const [selectedSubcategory, setSelectedSubcategory] = useState<ISubcategoryConfiguration>();
    const [banner, setBanner] = useState<IBanner>();
    const [imageUploadError, setImageUploadError] = useState<string>("");
    const authContext = useContext(AuthContext);

    useEffect(() => {
        apiCaller
            .get('/categories')
            .then((response: AxiosResponse) => {
                const data = response.data.data;
                setCategories(data);
            }).catch((error) => {

            });
    }, []);

    useEffect(() => {
        if (isMounted){
            const tempoEmMilissegundos = 4.5 * 1000;
            const timeoutId = setTimeout(clearError, tempoEmMilissegundos);
            return () => {
                clearTimeout(timeoutId)
            };
        } else {
            setIsMounted(true)
        }
    }, [imageUploadError]);

    useEffect(() => {
        if (selectedCategory && !selectedSubcategory) {
            apiCaller
                .get(`/covers/${selectedCategory.cover_id}`, {
                    headers: {
                        Authorization: `Bearer ${authContext?.getToken()}`
                    }
                })
                .then((response: AxiosResponse) => {
                    const data = response.data.data;
                    setBanner(data);
                }).catch((error) => {

                })
        } else if (selectedCategory && selectedSubcategory) {
            apiCaller
                .get(`/covers/${selectedSubcategory.cover_id}`, {
                    headers: {
                        Authorization: `Bearer ${authContext?.getToken()}`
                    }
                })
                .then((response: AxiosResponse) => {
                    const data = response.data.data;
                    setBanner(data);
                }).catch((error) => {
                    
                })
        }
    }, [selectedCategory, selectedSubcategory]);

    function clearError(){
        setImageUploadError("")
    }

    function handleImageUpload(files: any) {
        const file: File = files[0];
        const reader = new FileReader();

        reader.onload = (e: any) => {
            const image = new Image();
            
            image.src = e.target.result;
            image.onload = () => {
                if (image.width / image.height !== imageRatio){
                    setImageUploadError("Dimensões da imagem não suportadas. Utilize imagens com proporções multiplas de 1536 x 400.")
                } else {
                    const base64String = reader.result as string;
                
                    if (typeof base64String === "string") {
                        const categoryString = selectedCategory !== undefined ? selectedCategory.name : '';
                        const subCategoryString = selectedSubcategory !== undefined ? `-${selectedSubcategory.name}` : '';
                        const extension = file.name.split('.')[1];
                        const filename: string = `${categoryString}${subCategoryString}.${extension}`;

                        apiCaller
                            .post('/covers/', {
                                filename: filename,
                                content: base64String,
                                url: `${categoryString}${subCategoryString}`
                            }, {
                                headers: {
                                    Authorization: `Bearer ${authContext?.getToken()}`
                                }
                            })
                            .then((response: AxiosResponse) => {
                                const data = response.data.data;
                                setBanner(data);

                                if (selectedCategory && !selectedSubcategory) {
                                    apiCaller
                                        .put(`/categories/${selectedCategory.id}`, {
                                            cover_id: data.id
                                        }, {
                                            headers: {
                                                Authorization: `Bearer ${authContext?.getToken()}`
                                            }
                                        })
                                        .then((response: AxiosResponse) => {
                                            setSelectedCategory(response.data.data);
                                            window.location.reload();
                                        }).catch((error) => {

                                        })
                                } else if (selectedCategory && selectedSubcategory) {
                                    apiCaller
                                        .put(`/categories/${selectedCategory.id}/${selectedSubcategory.id}`, {
                                            cover_id: data.id
                                        }, {
                                            headers: {
                                                Authorization: `Bearer ${authContext?.getToken()}`
                                            }
                                        })
                                        .then((response: AxiosResponse) => {
                                            setSelectedSubcategory(response.data.data);
                                            window.location.reload();
                                        }).catch((error) => {
                                            
                                        })
                                }
                            })
                            .catch((error) => {

                            });
                    }
                }
            }
        }

        reader.readAsDataURL(file);
    }

    function parseDropdownOption(categories: ISubcategoryConfiguration[]) {
        const options = categories.map((cat) => cat.name);
        return options;
    }

    function handleCategoryPageSelect(option: string) {
        setSelectedCategory(
            categories
                .find((cat) => cat.name === option
        ));        
    }

    function handleSubcategoryPageSelect(option: string) {
        setSelectedSubcategory(
            selectedCategory!
                .subcategories
                .find((subcat) => subcat.name === option
        ));        
    }


    function upload(){
        document.getElementById("admin-settings-category-page-image-upload-field")?.click()
    }

    function getImage() {

        if (selectedCategory && selectedCategory.cover_id && !selectedSubcategory) {
            return getCoverImageUrl(`${banner?.filename}`);
        } else if (selectedCategory && selectedSubcategory && selectedSubcategory.cover_id) {
            return getCoverImageUrl(`${banner?.filename}`);
        } else {
            return CategoryPlaceholder;
        }
    }

    return (
        <div id="admin-settings-category-page-main">
            <div id="admin-settings-category-page-label">
                <p id="admin-settings-category-page-labe-txt">
                    Página de Categorias
                </p>
            </div>
            <div id="admin-settings-category-page-selector">
                <div id="admin-settings-category-page-selector-container">
                    <Dropdown
                        options={parseDropdownOption(categories)}
                        onSelect={handleCategoryPageSelect}
                        placeholder="Categoria"
                    />
                    
                </div>
                <div id="admin-settings-category-page-selector-container">
                {
                        selectedCategory?.subcategories &&
                        selectedCategory.subcategories.length > 0 &&
                        <Dropdown
                            options={parseDropdownOption(selectedCategory.subcategories)}
                            onSelect={handleSubcategoryPageSelect}
                            placeholder="Subcategoria"
                        />
                }
                </div>
            </div>
            <div id="admin-settings-category-page-image-container">
                <img 
                    id="admin-settings-category-page-image"
                    src={getImage()}
                    alt="" 
                    draggable={false}
                />
            </div>
            <div id="admin-settings-category-page-image-warning">
                {
                    imageUploadError &&
                    (
                        <p id="admin-settings-category-page-image-warning-txt">
                            {imageUploadError}
                        </p>
                    )
                }
            </div>
            {
                selectedCategory &&
                (
                    <div id="admin-settings-category-page-image-upload">
                        <div id="admin-settings-category-page-image-upload-btn-container">
                            <input 
                                id={"admin-settings-category-page-image-upload-field"}
                                style={{display: "none"}} 
                                type="file" 
                                onChange={(e) => {handleImageUpload(e.target.files)}}
                            />
                            <div id="admin-settings-category-page-image-upload-btn" onClick={upload}>
                                <p id="admin-settings-category-page-image-upload-btn-txt">
                                    Adicionar Imagem
                                </p>
                            </div>
                        </div>
                    </div>

                )
            }
        </div>
    )
}