import React, {useCallback, useEffect, useState} from "react"

import "./textInput.css"

import NumberValidade from "misc/numberValidade"

export default function TextInput(
    props: {
        label: string, 
        value?: string, 
        disabled?: boolean, 
        monetary?: boolean,
        numeric?: boolean
        onValueChange: Function
    }
){

    const [value, setValue] = useState<string>("");

    useEffect(() => {
        if (props.value === "0"){
            setValue("")
        } else {
            setValue(props.value || "");
        }
    }, [props.value]);

    function handleOnChange(value: string) {
        handleValueChange(value);
    }

    function handleValueChange(value: string){
        if (props.numeric === true && props.monetary !== undefined) {
            if (NumberValidade.isNumericString(value) || value === "" || value.includes(".")){
                setValue(value)
                props.onValueChange(value)
            }
        } else {
            setValue(value);
            props.onValueChange(value)
        }
    }

    return (
        <div id="text-input-main">
            <div id="text-input-text-label-container">
                <p id="text-input-text-label">
                    {props.label}
                </p>
            </div>
            <div id="text-input-text-field-container">
                {
                    props.monetary &&
                    (
                        <p id="text-input-currency">
                            R$
                        </p>
                    )
                }
                <input 
                    id="text-input-text-field" 
                    onChange={(e) => {handleOnChange(e.target.value)}}
                    disabled={props.disabled} 
                    value={value}
                />
            </div>
        </div>
    )
}
