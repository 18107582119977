export function getProductImageUrl(filename: string) {
    return `${process.env.REACT_APP_API_URL}/cdn/images/product/${filename}`
}

export function getCoverImageUrl(filename: string) {
    return `${process.env.REACT_APP_API_URL}/cdn/images/cover/${filename}`
}

export function getBannerImageUrl(filename: string) {
    return `${process.env.REACT_APP_API_URL}/cdn/images/banner/${filename}`
}