import React, {useState, useEffect, useContext} from "react"

import "./exchangesAndReturns.css"

import Header from "components/header/header"
import Footer from "components/footer/footer"

export default function ExchangesAndReturns(){

    const [screenHeight, setScreenHeight] = useState<number>(0)

    useEffect(() => {
        const headerElement = window.document.getElementById("header-main")
        const productCategoryHeader = window.document.getElementById("exchanges-and-returns-template-header")
        
        if (headerElement && productCategoryHeader){
            const headerHeight = headerElement.getBoundingClientRect().height
            const pageTemplateHeaderHeight = productCategoryHeader.getBoundingClientRect().height

            let height = window.screen.availHeight
            height = height - (window.outerHeight - window.innerHeight)
            height = height - headerHeight
            height = height - pageTemplateHeaderHeight
            setScreenHeight(height)
        }
    },[window.innerHeight])


  
    return (
        <>  
            <Header/>
            <div id="exchanges-and-returns-template-main">
                <div id="exchanges-and-returns-template-content">
                    <div id="exchanges-and-returns-template-header">
                        <p id="exchanges-and-returns-header-txt">
                            Trocas e Devoluções
                        </p>
                        <p id="exchanges-and-returns-subheader-txt">
                            
                        </p>
                    </div>  
                    <div id="exchanges-and-returns-template-list" style={{minHeight: screenHeight}}>
                        <p className="exchanges-and-returns-text exchanges-and-returns-text-category">
                            Prazo para Troca e Devolução
                        </p>
                        <p className="exchanges-and-returns-text exchanges-and-returns-space">
                            Você tem até 7 dias corridos após o recebimento do produto para solicitar a troca ou devolução. Basta entrar em contato conosco pelo e-mail ou WhatsApp nesse período, e estaremos prontos para orientar você sobre os próximos passos para a devolução.
                        </p>
                        <p className="exchanges-and-returns-text exchanges-and-returns-text-category">
                            Condições para Troca ou Devolução
                        </p>
                        <p className="exchanges-and-returns-text exchanges-and-returns-space">
                            Não pode ter sido utilizado ou lavado.
                        </p>
                        <p className="exchanges-and-returns-text exchanges-and-returns-space">
                            Deve estar com todas as etiquetas e embalagens originais em perfeito estado.
                        </p>
                        <p className="exchanges-and-returns-text exchanges-and-returns-space">
                            Não pode apresentar sinais de uso, manchas ou alterações feitas pelo cliente.
                        </p>
                        <p className="exchanges-and-returns-text exchanges-and-returns-text-category">
                            Produtos que Não Podem Ser Trocados
                        </p>
                        <p className="exchanges-and-returns-text exchanges-and-returns-space">
                            Peças em promoção e acessórios não estão incluídos na política de trocas e devoluções.
                        </p>
                        <p className="exchanges-and-returns-text exchanges-and-returns-text-category">
                            Procedimento de Troca ou Devolução
                        </p>
                        <p className="exchanges-and-returns-text exchanges-and-returns-space">
                            Após o recebimento do produto devolvido e a confirmação de que ele atende às condições indicadas, daremos continuidade ao processo de troca ou reembolso, conforme sua preferência.
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}