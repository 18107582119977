import React, { useEffect, useState, useContext } from "react"

import "./shipped.css"

import OrderCard         from "../components/orderCard/orderCard"
import Details           from "./components/details/details"
import CancelOrder       from "../components/cancelOrder/cancelOrder"
import IOrder            from "interfaces/order/IOrder"
import AdminPageTemplate from "pages/admin/components/adminPageTemplate/adminPageTemplate"
import { apiCaller } from "api/apiCaller"
import { AxiosError, AxiosResponse } from "axios"
import { AuthContext } from "context/authProvider"

export default function AdminShippedOrders(props: {}){

    const authContext = useContext(AuthContext)

    const [orders, setOrders] = useState<any[]>([])
    const [selectedOrder, setSelectedOrder] = useState<any>()
    const [cancelOrder, setCancelOrder] = useState<boolean>(false)
    const [cancelOrderId, setCancelOrderId] = useState<string>("")

    useEffect(() => {
        const token = `Bearer ${authContext?.getToken()}`

        apiCaller.get("/orders/?status=SENT",
            {
                headers: {
                    "Authorization": token
                }
            }).then((response: AxiosResponse) => {
                console.log(response.data.data)
                setOrders(response.data.data)
        }).catch((error: AxiosError) => {
            console.log(error)
        })
    },[])

    function handleOrderClick(id: string){
        let order = orders.find(order => order.id === id);
        if (order){
            setSelectedOrder(order)
        }
    }

    function handleForwardOrder(id: string){
        const token = `Bearer ${authContext?.getToken()}`

        apiCaller.put(`/orders/${id}/forward`, null,{
            headers: {
                "Authorization": token
            }
        }).then((response: AxiosResponse) => {

            setSelectedOrder(null)

            apiCaller.get("/orders/?status=SHIPPED",{
                headers: {
                    "Authorization": token
                }
            }).then((response: AxiosResponse) => {
               setOrders(response.data.data)
            }).catch((error: AxiosError) => {
            })
        }).catch((error: AxiosError) => {
        })
    }
    
    function handleRufuseCancel(){
        setCancelOrder(false)
    }
    
    function handleRenderCancelOrderModal(id: string){
        setCancelOrder(true)
        setCancelOrderId(id)
    }
    
    function handleCancelOrder(){
        /** chamada da api */
    }

    return (
        <AdminPageTemplate navigationUrl="/admin/pedidos-enviados">
            <div id="admin-shipped-orders-main">
                <div id="admin-shipped-orders-order-list-container">
                    <div id="admin-shipped-orders-order-list-label">
                        <p id="admin-shipped-orders-order-list-label-number-text" className="admin-shipped-orders-order-list-label-text">
                            Número
                        </p>
                        <p id="admin-shipped-orders-order-list-label-date-text" className="admin-shipped-orders-order-list-label-text">
                            Data
                        </p>
                    </div>
                    <ul id="admin-shipped-orders-order-list">
                        {
                            orders.map(order => {
                                return (
                                    <OrderCard 
                                        key={order.id}
                                        data={order} 
                                        onOrderClick={handleOrderClick} 
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
                { 
                    selectedOrder && !cancelOrder &&
                    (
                        <Details
                            data={selectedOrder}
                            onCancelOrder={handleRenderCancelOrderModal}
                            onForwardOrder={handleForwardOrder}
                        />
                    )
                }
                {
                    !selectedOrder && !cancelOrder &&
                    (
                        <div id="admin-paid-orders-placeholder">
                            <p id="admin-paid-orders-placeholder-txt">
                                Selecione um pedido no menu ao lado
                            </p>
                        </div>
                    )
                }
                {   
                    cancelOrder &&
                    (
                        <CancelOrder
                            id={cancelOrderId}
                            onRefuseCancel={handleRufuseCancel}
                            onCancelOrder={handleCancelOrder}
                        />
                    )
                }
            </div>
        </AdminPageTemplate>
    )
}