import React, {useState, useEffect, ReactNode, createContext} from "react"

import { IProduct, IProductVariation } from "interfaces/product/IProduct"
import { apiCaller } from "api/apiCaller"
import { AxiosError, AxiosResponse } from "axios"
interface ICartContext {
    addProduct: Function
    getProducts: Function
    updateProducts: Function,
    increaseQuantity: Function
    decreaseQuantity: Function
    resetCart: Function
    productQuantity: number
}

export const CartStorageContext = createContext<ICartContext | undefined>(undefined);

export default function CartStorageProvider(props: {children?: ReactNode}): JSX.Element{

    const [productQuantity, setProductQuantity] = useState<number>(0)

    useEffect(() => {
        handleGetProductQuantity()
    },[])

    function resetCart(){
        setProductQuantity(0)
    }

    function handleUpdateProducts(){
        let cart = localStorage.getItem("cart")

        if (cart){
            let parsedCart: {quantity: number, data: IProduct}[] = JSON.parse(cart)
            const productsIds = parsedCart.map((cartProduct) => {
                return cartProduct.data.id
            })

            apiCaller.post("/products/find-ids", {
                // featured: true,
                ids: productsIds
            }).then((response: AxiosResponse) => {
                let fetchedProducts: Array<IProduct> = response.data.data
                let updatedCart: {quantity: number, data: IProduct}[] = []

                for (let index1 = 0; index1 < parsedCart.length; index1++) {
                    
                    let product = fetchedProducts.find((product) => {
                        if (product.id === parsedCart[index1].data.id){
                            return product
                        }
                    })

                    let variation = product?.variations.find((variation) => {
                        if (variation.id === parsedCart[index1].data.variations[0].id){
                            return variation
                        }
                    })

                    let size = variation?.sizes.find((size) => {
                        if (size.id === parsedCart[index1].data.variations[0].sizes[0].id){{
                            return size
                        }}
                    })

                    if (product && variation && size){

                        let updatedProduct: IProduct = product
                        updatedProduct.variations = []
                        updatedProduct.variations.push(variation)

                        updatedProduct.variations[0].sizes = []
                        updatedProduct.variations[0].sizes.push(size)

                        updatedCart.push({ quantity: parsedCart[index1].quantity, data: updatedProduct })
                    }
                }

                // localStorage.setItem("cart", JSON.stringify(updatedCart)) 

            }).catch((error: AxiosError) => {
    
            })
        }
    }

    function handleGetProductQuantity(){
        let cart = localStorage.getItem("cart")
        if (cart){
            let counter: number = 0
            let parsedCart: {quantity: number, data: IProduct}[] = JSON.parse(cart)
            
            parsedCart.forEach((cartProduct) => {
                counter = counter + cartProduct.quantity
            })
            setProductQuantity(counter)
        }
    }

    function handleAddProduct(data: IProduct){
        let error = false
        if (localStorage.getItem("cart") === null){
            if (data.variations[0].sizes[0].quantity > 0){
                localStorage.setItem("cart", JSON.stringify(
                    [
                        {   
                            quantity: 1,
                            data
                        }
                    ]
                )) 
            } else {
                return false
            }
        } else {
            let cart = localStorage.getItem("cart")
            if (cart){
                let isNew: boolean = true
                let parsedCart: {quantity: number, data: IProduct}[] = JSON.parse(cart)
                parsedCart.forEach((cartProduct) => {
                    if (
                        cartProduct.data.id === data.id && 
                        cartProduct.data.variations[0].id === data.variations[0].id &&
                        cartProduct.data.variations[0].sizes[0].size === data.variations[0].sizes[0].size
                    ){
                        if (cartProduct.quantity < cartProduct.data.variations[0].sizes[0].quantity){
                            cartProduct.quantity = cartProduct.quantity + 1
                            isNew = false
                            return true
                        } else {
                            error = true
                            return false
                        }
                    }
                })

                if (!error){
                    if (isNew){
                        parsedCart.push({ quantity: 1, data })
                        localStorage.setItem("cart", JSON.stringify(parsedCart)) 
                    }
                }
            }
        }
        if (!error){
            setProductQuantity(productQuantity + 1)
        }
    }

    function handleRemoveProduct(id: string, variationId: string, size: string){
        var cart = localStorage.getItem("cart")
        if (cart){
            var parsedCart: {quantity: number, data: IProduct}[] = JSON.parse(cart)
            const a = parsedCart.filter((cartProduct) => {
                if (
                    cartProduct.data.id !== id || 
                    cartProduct.data.variations[0].id !== variationId ||
                    cartProduct.data.variations[0].sizes[0].size !== size
                ){
                    return cartProduct
                } else {
                    setProductQuantity(productQuantity - cartProduct.quantity)
                }
            })
            localStorage.setItem("cart", JSON.stringify(a))
        }
    }

    function handleGetProduct(){
        if (localStorage.getItem("cart") !== null){
            var cart = localStorage.getItem("cart")
            if (cart){
                var parsedCart = JSON.parse(cart)

                return parsedCart
            }
            else {
                return []
            }
        } else {
            return []
        }
    }

    function handleDecreaseQuantity(id: string, variationId: string, size: string){
        var cart = localStorage.getItem("cart")
        if (cart){
            var parsedCart: {quantity: number, data: IProduct}[] = JSON.parse(cart)
            parsedCart.forEach((cartProduct) => {
                if (
                    cartProduct.data.id === id && 
                    cartProduct.data.variations[0].id === variationId &&
                    cartProduct.data.variations[0].sizes[0].size === size
                ){
                    cartProduct.quantity = cartProduct.quantity - 1
                }
            })

            const a = parsedCart.filter((cartProduct) => {
                if (cartProduct.quantity >= 1){
                    return cartProduct
                }
            })

            localStorage.setItem("cart", JSON.stringify(a))
            setProductQuantity(productQuantity - 1)
        }
    }

    function handleIncreaseQuantity(id: string, variationId: string, size: string): boolean {
        let error = false
        let cart = localStorage.getItem("cart")
        if (cart){
            let parsedCart: {quantity: number, data: IProduct}[] = JSON.parse(cart)
            parsedCart.forEach((cartProduct) => {
                if (
                    cartProduct.data.id === id && 
                    cartProduct.data.variations[0].id === variationId &&
                    cartProduct.data.variations[0].sizes[0].size === size
                ){
                    if (cartProduct.quantity < cartProduct.data.variations[0].sizes[0].quantity){
                        cartProduct.quantity = cartProduct.quantity + 1
                        return true
                    } else {
                        error = true
                        return false
                    }
                }
            })

            if (error){
                return false
            }

            localStorage.setItem("cart", JSON.stringify(parsedCart))
            setProductQuantity(productQuantity + 1)

            return true
        }
        return false
    }
    

    return (
        <CartStorageContext.Provider value={
            {
                addProduct: handleAddProduct,
                getProducts: handleGetProduct,
                updateProducts: handleUpdateProducts,
                increaseQuantity: handleIncreaseQuantity,
                decreaseQuantity: handleDecreaseQuantity,
                productQuantity: productQuantity,
                resetCart: resetCart
            }
        }>
            {props.children}
        </CartStorageContext.Provider>
    )
}