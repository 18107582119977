import React, { useEffect, useState, useContext } from "react"

import "./userOrders.css"

import UserProfileTemplate  from "../components/userPageTemplate/userPageTemplate"
import UserProfileOrderCard from "./components/userProfileOrderCard/userProfileOrderCard"
import Order                from "assets/icons/pages/userOrders/screen-cart.png"
import IOrder               from "interfaces/order/IOrder"
import { apiCaller } from "api/apiCaller"
import { AxiosError, AxiosResponse } from "axios"
import { AuthContext } from "context/authProvider"


import ROUPA from "assets/roupa.jpeg"

export default function UserOrders(){
    const authContext = useContext(AuthContext)
    
    const [orders, setOrders] = useState<any[]>([])

    useEffect(() => {
        const token = `Bearer ${authContext?.getToken()}`

        apiCaller.get("/orders/",{
            headers: {
                "Authorization": token
            }
        }).then((response: AxiosResponse) => {
           console.log(response.data.data)
           setOrders(response.data.data);
        }).catch((error: AxiosError) => {
            console.log(error)
        })
    }, []);
    
    
    return (
        <UserProfileTemplate image={Order} name="Pedidos" navigationUrl="/perfil/pedidos">
            <div id="user-profile-orders-menu">
                {/* <Menu/> */}
            </div>
            <ul id="user-profile-orders-menu-list">
                {
                    orders.map((order) => {
                        return (
                            <UserProfileOrderCard
                                key={order.id}
                                data={order}
                            />
                        )
                    })
                }
            </ul>
        </UserProfileTemplate>
    )
}