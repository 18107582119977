import React, {useState, useEffect, createContext, useContext} from "react"

import "./mainPage.css"

import BannerPlaceholder from "assets/backgrounds/banner-placeholder.svg"
import IBanner from "interfaces/IBanner"
import BannerList from "./components/bannerList/bannerList"
import NewBanner from "./components/newBanner/newBanner"
import BannerDetail from "./components/bannerDetail/bannerDetail"
import { AxiosError, AxiosResponse } from "axios"
import { AuthContext } from "context/authProvider"
import { apiCaller } from "api/apiCaller"

interface INavigation {
    list: boolean
    new: boolean
    detail: boolean
}

interface IBannerConfigurationContext {
    selectedBanner: IBanner
    setSelectedBanner: React.Dispatch<React.SetStateAction<IBanner>>
    navigate: Function
}

export const BannerConfigurationContext = createContext<IBannerConfigurationContext | undefined>(undefined)

export default function MainPage(){

    const imageRatio = 3.84
    
    const authContext = useContext(AuthContext)
    const [selectedBanner,   setSelectedBanner] = useState<IBanner>({
        id:    "",
        url:   "",
        filename:  "",
        content: ""
    })
    const [error,  setError ] = useState<string>("")

    const [navigationController, setNavigationController] = useState<INavigation>({
        list: true,
        new: false,
        detail: false
    })

    // useEffect(() => {
    //     if (isMounted){
    //         const tempoEmMilissegundos = 4.5 * 1000;
    //         const timeoutId = setTimeout(clearError, tempoEmMilissegundos);
    //         return () => {
    //             clearTimeout(timeoutId)
    //         };
    //     } else {
    //         setIsMounted(true)
    //     }
    // }, [imageUploadError])

    const [filename, setFilename] = useState<string>('');

    function handleNavigation(value: string) {
        for (const key in navigationController) {
            if (navigationController.hasOwnProperty(key)) {
                const keyTyped = key as keyof INavigation

                if (keyTyped === value) {
                    setNavigationController((prev) => ({
                        ...prev,
                        [keyTyped]: true
                    }))
                } else {
                    setNavigationController((prev) => ({
                        ...prev,
                        [keyTyped]: false
                    }))
                }
            }
        }
    }

  
    function handleCreateBanner(){
        if (selectedBanner.content === "") {
            setError("Faça o upload do banner.")
        } 
        else {
            const token = `Bearer ${authContext?.getToken()}`
    
            apiCaller.post("/banners/", {
                filename: filename,
                url: selectedBanner.url,
                content: selectedBanner.content
            }, {
                headers: {
                    "Authorization": token
                }
            }).then((response: AxiosResponse) => {
                console.log(response.data)
            }).catch((error: AxiosError) => {
                console.log(error.message)
            }).finally(() => {
                setSelectedBanner({
                    id:    "",
                    url:   "",
                    filename:  "",
                    content: ""
                })

                handleNavigation("list")
            })
        }
    }

    function handleDeleteBanner(){
        const token = `Bearer ${authContext?.getToken()}`

        apiCaller.delete(`/banners/${selectedBanner.id}`,
        {   
            headers: {
                "Authorization": token
            }
        }).then((response: AxiosResponse) => {
            console.log(response.data)
        }).catch((error: AxiosError) => {
            console.log(error.message)
        }).finally(() => {
            setSelectedBanner({
                id:    "",
                url:   "",
                filename:  "",
                content: ""
            })

            handleNavigation("list")
        })
    }

    function handleCancel(){
        handleNavigation("list")
        setSelectedBanner(
            {
                id: "",
                url: "",
                filename: "",
                content: ""
            }
        )
    }

    return (
        <BannerConfigurationContext.Provider value={
            {
                selectedBanner: selectedBanner,
                setSelectedBanner: setSelectedBanner,
                navigate: handleNavigation
            }
        }>
            <div id="admin-settings-catalog-banners-main">
                <div id="admin-settings-catalog-banners-btns-container">
                    <p id="admin-settings-catalog-banners-warning">
                        {error}
                    </p>
                    {
                        navigationController.list &&
                        (
                            <p 
                                id="admin-settings-catalog-banners-new-btn"
                                className="admin-settings-catalog-banners-btns" 
                                onClick={() => {handleNavigation("new")}}
                            >
                                Novo banner
                            </p>
                        )
                    }
                    {
                        navigationController.new &&
                        (
                            <>
                                <p 
                                    id="admin-settings-catalog-banners-cancel-btn"
                                    className="admin-settings-catalog-banners-btns" 
                                    onClick={handleCancel}
                                >
                                    Cancelar
                                </p>
                                <p 
                                    id="admin-settings-catalog-banners-save-btn"
                                    className="admin-settings-catalog-banners-btns" 
                                    onClick={handleCreateBanner}
                                >
                                    Salvar
                                </p>
                            </>
                        )
                    }
                    {
                        navigationController.detail &&
                        (
                            <>
                                <p 
                                    id="admin-settings-catalog-banners-back-btn"
                                    className="admin-settings-catalog-banners-btns" 
                                    onClick={handleCancel}
                                >
                                    Voltar
                                </p>
                                <p 
                                    id="admin-settings-catalog-banners-delete-btn"
                                    className="admin-settings-catalog-banners-btns" 
                                    onClick={handleDeleteBanner}
                                >
                                    Deletar
                                </p>
                            </>
                        )
                    }
                </div>
                {
                    navigationController.list &&
                    (
                        <BannerList/>
                    )
                }
                {
                    navigationController.new && 
                    (
                        <NewBanner setFilename={setFilename}/>
                    )
                }
                {
                    navigationController.detail && 
                    (
                        <BannerDetail/>
                    )
                }
            </div>
        </BannerConfigurationContext.Provider>
    )
}