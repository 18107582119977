import React, { useEffect, useState, useId } from "react"

import "./dropdownWithoutLabel.css"

import OrderIcon from "assets/icons/up-down.png"

import Option from "./components/option"

export default function Dropdown(
    props: {
        options: string[], 
        placeholder?: string,
        orderSelection?: boolean,
        defaultOption?: string,
        onSelect: Function,
        onChangeOrder?: Function
    }
){

    const componentId = useId()

    const [options, setOptions] = useState<string[]>([])
    const [selectedOption, setSelectedOption] = useState<string>("")

    const [width,          setWidth         ] = useState<number>(0)
    const [headerHover,    setHeaderHover   ] = useState<boolean>(false)
    const [bodyHover,      setBodyHover     ] = useState<boolean>(false)
    const [renderDropDown, setRenderDropDown] = useState<boolean>(false)

    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [position, setPosition] = useState<any>({positionX: 0, positionY: 0})
    
    useEffect(() => {

        if (props.defaultOption){
            setSelectedOption(props.defaultOption)
        }

        setOptions(props.options)
    },[props.options])

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        
        window.addEventListener("resize", handleResize);
        
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    },[]);

    useEffect(() => {
        setRenderDropDown(false)
    }, [windowDimensions]);
    
    
    useEffect(() => {
        const elemento = document.getElementById(`basic-dropdown-without-label-${componentId}-btn`);
        if (elemento != null) {
            const rect = elemento.getBoundingClientRect();
            setWidth(rect.width * 1)
            setPosition({
                X: rect.left,
                Y: rect.top + rect.height
            })
        }
        
    }, [window.innerWidth,window.innerHeight])
    
    useEffect(() => {
        if (headerHover || bodyHover){
            setRenderDropDown(true)
        } else {
            setRenderDropDown(false)
        }
    }, [bodyHover, headerHover])

    

    function handleCartHover(element: string){
        if (element === "header"){
            setHeaderHover(true)
        } 
        if (element === "body"){
            setBodyHover(true)
        }
    }

    function handleCartUnhover(element: string){
        if (element === "header"){
            setHeaderHover(false)
        } 
        if (element === "body"){
            setBodyHover(false)
        }
    }

    function handleSelect(option: string){
        setSelectedOption(option)
        setRenderDropDown(false)
        props.onSelect(option)
    }

    function handleChangeOrder(){
        if (props.onChangeOrder){
            props.onChangeOrder()
        }
    }

    return (
        <div id="basic-dropdown-without-label-main">
            <div className="basic-dropdown-without-label-btn">  
                {
                    !props.orderSelection &&
                    (
                        <div 
                            className="basic-dropdown-without-label-value-container" 
                            id={`basic-dropdown-without-label-${componentId}-btn`}
                            onMouseEnter={() => {handleCartHover("header")}}
                            onMouseLeave={() => {handleCartUnhover("header")}}
                        >
                            <p id="basic-dropdown-without-label-value-txt">
                                {(selectedOption === "") ? props.placeholder : selectedOption}
                            </p>
                        </div>

                    )
                }
                {
                    props.orderSelection &&
                    (   
                        <>
                            <div 
                                className="basic-dropdown-without-label-order-selector-value-container"
                                id={`basic-dropdown-without-label-${componentId}-btn`}
                                onMouseEnter={() => {handleCartHover("header")}}
                                onMouseLeave={() => {handleCartUnhover("header")}}    
                            >
                                <p id="basic-dropdown-without-label-value-txt">
                                    {(selectedOption === "")? props.placeholder: selectedOption}
                                </p>
                            </div>
                            <div id="basic-dropdown-without-label-order-selector-selector">
                                <img 
                                    id="basic-dropdown-without-label-order-selector-selector-img"
                                    src={OrderIcon} 
                                    alt="" 
                                    draggable={false}
                                    onClick={handleChangeOrder}
                                />
                            </div>
                        </>
                    )
                }
            </div>
            <ul id="basic-dropdown-without-label-list" 
                style={{display: renderDropDown? "flex": "none", top: position.Y, left: position.X, width: width}}
                onMouseEnter={() => {handleCartHover("body")}}
                onMouseLeave={() => {handleCartUnhover("body")}}
            >
                {
                    options.map((option) => {
                        return (
                            <Option
                                key={option}
                                value={option}
                                onSelect={handleSelect}
                            />
                        )
                    })
                }
            </ul>
        </div>
    )
}