import React, { useEffect } from "react"

import "./orderCard.css"

import IOrder         from "interfaces/order/IOrder"
import TimeDateParser from "misc/timeDateParser"
import ITimestamp     from "interfaces/ITimestamp"

export default function OrderCard(props: {data: any, onOrderClick: Function}){

    useEffect(() => {
    },[])

    function handleOrderClick(){
        props.onOrderClick(props.data.id)
    }

    function parseTimestamp(timestamp: ITimestamp): string {
        let date = TimeDateParser.parseDate(
            timestamp.date.day,
            timestamp.date.month,
            timestamp.date.year
        )

        let time = TimeDateParser.parseTime(
            timestamp.time.hour,
            timestamp.time.minute
        )

        return `${date} ${time}`

    }

    return (
        <li id="admin-order-card-main" onClick={handleOrderClick}>
            <div id="admin-order-card-main-order-id-container">
                <p id="admin-order-card-main-order-id">
                    {props.data.id.split("-")[0]}
                </p>
            </div>
            <div id="admin-order-card-main-order-date-container">
                <p id="admin-order-card-main-order-date">
                    {props.data.createdAt.split(" ")[0]}
                </p>
                {/* {
                    props.data.timeStamp &&
                    (
                        <p id="admin-order-card-main-order-date">
                            {parseTimestamp(props.data.timeStamp)}
                        </p>
                    )
                } */}
            </div>
            
        </li>
    )
}