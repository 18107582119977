import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"

import "./register.css"

import useExecute from "hooks/useExecute"
import Logo from "assets/logo/logoRoundWithBackgroundCropped.png"
import BackIcon from "assets/icons/back.png"
import TextField    from "components/inputs/textfieldUnderline/textfieldUnderline"
import PasswordField from "components/inputs/passwordfieldUnderline/passwordfieldUnderline"
import { AccessContext } from "pages/access/access"
import { apiCaller } from "api/apiCaller"
import { AxiosError, AxiosResponse } from "axios"
import LoadingAnimation from "components/animations/loadingAnimation"

export default function Register(props: {}){

    const accessContext = useContext(AccessContext)

    const [name,            setName           ] = useState("")
    const [surname,         setSurname        ] = useState("")
    const [email,           setEmail          ] = useState("")
    const [cpf,             setCPF            ] = useState("")
    const [password,        setPassword       ] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error,           setError          ] = useState("")
    const [isLoading,       setIsLoading      ] = useState<boolean>(false)

    useExecute(4.6, error, handleResetError)

    function handleNameChange(value: string) {
        setName(value);
    }

    function handleSurnameChange(value: string) {
        setSurname(value);
    }

    function handleEmailChange(value: string) {
        setEmail(value);
    }

    function handleCpfChange(value: string) {
        setCPF(value);
    }

    function handlePasswordChange(value: string) {
        setPassword(value);
    }

    function handleconfirmPasswordChange(value: string) {
        setConfirmPassword(value);
    }

    function handleRegister(){
        let errorMessage: string = "campos inválidos: "
        let error = false

        if (name === ""){
            error = true
            errorMessage = errorMessage.concat("nome, ")
        }
        if (surname === ""){
            error = true
            errorMessage = errorMessage.concat("sobrenome, ")
        }
        if (email === ""){
            error = true
            errorMessage = errorMessage.concat("email, ")
        }
        if (cpf === ""){
            error = true
            errorMessage = errorMessage.concat("cpf, ")
        }
        if (password === ""){
            error = true
            errorMessage = errorMessage.concat("senha, ")
        } 
        if (confirmPassword === ""){
            error = true
            errorMessage = errorMessage.concat("confirmação de senha, ")
        }
        else {
            let triggerAnimation: boolean = true
    
            setTimeout(() => {
                if (triggerAnimation){
                    setIsLoading(true)
                }
            }, 100)
            apiCaller.post("/auth/signup", {
                name: name,
                surname: surname,
                password: password,
                cpf: cpf,
                email: email
            }).then((response: AxiosResponse) => {
                if (triggerAnimation) {
                    setIsLoading(false)
                    triggerAnimation = false
                } 

                accessContext?.setUser({
                    email: email,
                    password: password
                })
                accessContext?.navigate("confirm")

            }).catch((error: AxiosError) => {
                if (triggerAnimation) {
                    setIsLoading(false)
                    triggerAnimation = false
                }
                setError("verifique os dados e tente novamente")
            })
        }

        if (error){
            let lastComma = errorMessage.lastIndexOf(",")
            errorMessage = errorMessage.substring(0, lastComma)
            setError(errorMessage)
        }
    }

    function handleLogin(){
        accessContext?.navigate("login")
    }

    function handleResetError(){
        setError("")
    }

    return (
            <div id="register-container">
                {
                    !isLoading &&
                    (
                        <>
                            <div id="register-logo-container">
                                <img 
                                    id="register-back-btn" 
                                    src={BackIcon} 
                                    alt="" 
                                    draggable={false}
                                    onClick={handleLogin}
                                />
                            </div>
                            <div id="register-content">
                                <div className="register-input-container">
                                    <TextField
                                        label="Nome"
                                        onValueChange={handleNameChange}
                                        onSelected={handleResetError}
                                    />
                                </div>
                                <div className="register-input-container">
                                    <TextField 
                                        label="Sobrenome"
                                        onValueChange={handleSurnameChange}
                                        onSelected={handleResetError}
                                    />
                                </div>
                                <div className="register-input-container">
                                    <TextField 
                                        label="E-mail"
                                        onValueChange={handleEmailChange}
                                        onSelected={handleResetError}
                                    />
                                </div>
                                <div className="register-input-container">
                                    <TextField 
                                        label="CPF"
                                        onValueChange={handleCpfChange}
                                        onSelected={handleResetError}
                                        maxCharacters={15}
                                        numeric={true}
                                    />
                                </div>
                                <div className="register-input-container">
                                    <PasswordField 
                                        label="Senha"
                                        onValueChange={handlePasswordChange}
                                        onSelected={handleResetError}
                                    />
                                </div>
                                <div className="register-input-container">
                                    <PasswordField 
                                        label="Confirme sua senha"
                                        onValueChange={handleconfirmPasswordChange}
                                        onSelected={handleResetError}
                                        onKeyDown={handleRegister}
                                    />
                                </div>
                            </div>
                            <div id="register-warning">
                                {
                                    error &&
                                    (
                                        <p id="register-warning-txt">
                                            {error}
                                        </p>
                                    )
                                }
                            </div>   
                            <div id="register-btns">
                                <div id="register-btns-register" onClick={handleRegister}>
                                    <p id="register-btns-register-txt">
                                        Registrar
                                    </p>
                                </div>
                                <p id="register-btns-login" onClick={handleLogin}>
                                    Login
                                </p>
                            </div>
                        </>
                    )
                }
                {
                    isLoading &&
                    (
                        <LoadingAnimation
                            style={{width: "250px", height: "250px"}}
                        />
                    )
                }
            </div>
    )
}