import React, {useState} from "react"

import "./cancelOrder.css"

import TextFieldLarge from "components/inputs/textFieldLarge/textFieldLarge"

export default function CancelOrder(props: {id: string, onRefuseCancel: Function, onCancelOrder: Function}){

    const [cancelMessage, setCancelMessage] = useState<string>("")

    function handleRufuseCancel(){
        props.onRefuseCancel()
    }

    function handleCancelOrder(){
        props.onCancelOrder(props.id, cancelMessage)
    }

    function handleCancelMessageChange(value: string){
        setCancelMessage(value)
    }

    return (
        <div id="cancel-order-modal-main">
            <div id="cancel-order-title">
                <p id="cancel-order-title-txt1">
                    Cancelamento do pedido
                </p>
                <p id="cancel-order-title-txt2">
                    {props.id.split("-")[0]}
                </p>
            </div>
            <div id="cancel-order-content">
                Tem certeza que deseja cancelar o pedido? Esta operação é irreversivel
            </div>
            <div id="cancel-order-btn-container">
                <p id="cancel-order-btn-confirm" onClick={handleCancelOrder}>
                    Confirmar
                </p>
                <p id="cancel-order-btn-cancel" onClick={handleRufuseCancel}>
                    Cancelar
                </p>
            </div>
        </div>
    )
}