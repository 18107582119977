import React, { useContext, useEffect, useState } from "react"

import "./userProfileAddressNew.css"

import TextField from "components/inputs/textfieldUnderline/textfieldUnderline"
import IShipmentAddress   from "interfaces/IShipmentAddress"
import Dropdown from "components/inputs/dropdownUnderline/dropdownUnderline"
import { AuthContext } from "context/authProvider"
import { AddressContext } from "pages/user/address/userAddress"
import { apiCaller } from "api/apiCaller"
import apiIBGEStates from "api/apiIBGEStates"
import apiIBGECity from "api/apiIBGECity"
import ICity from "interfaces/address/ICity"
import IState from "interfaces/address/IState"
import { AxiosError, AxiosResponse } from "axios"

export default function UserProfileAddressNew(props: {}){

    const authContext = useContext(AuthContext)
    const addressContext = useContext(AddressContext)

    const [stateFetchError, setStateFetchError] = useState<boolean>(false)
    const [error,           setError          ] = useState<string>("")

    const [address, setAddress] = useState<IShipmentAddress>({
        id: "0",
        name: "",
        address1: "",
        address2: "",
        unitNumber: 0,
        complement: "",
        city: "",
        state: "",
        postalCode: "",
        default: false
    })

    const [data, setData] = useState<IState[]>([])
    const [cityData, setCityData] = useState<ICity[]>([])

    useEffect(() => {
        apiIBGEStates()
            .then(mappedData => {
                setData(mappedData)
            })
            .catch(error => {
                console.error("api error", error)
                setStateFetchError(true)
            })
    }, []);

    useEffect(() => {
        if (!stateFetchError){
            apiIBGECity(address.state)
            .then(mappedData => {
                setCityData(mappedData);
            })
            .catch(error => {
                console.error("api error", error)
                setStateFetchError(true)
            });
        }
    },[address.state])

    useEffect(() => {
        console.log("resetou")
        setAddress((prev) => ({
            ...prev,
            city: ""
        }))
    },[address.state])

    
    function handleCancel(){
        addressContext?.navigate("addressList")
    }

    function handleFieldChange(field: string, value: string | number) {

        if (field === "state"){
            setAddress((prev) => ({
                ...prev,
                city: ""
            }))
        }

        for (const key in address){
            if (address.hasOwnProperty(key)){
                const keyTyped = key as keyof IShipmentAddress

                if (keyTyped === field){
                    if (field === "unitNumber" && typeof value === "string"){
                        setAddress((previous) => ({
                            ...previous,
                            "unitNumber": parseInt(value)
                        })) 
                    } else {
                        setAddress((previous) => ({
                            ...previous,
                            [keyTyped]: value
                        }))
                    }
                }
            }
        }
    }

    function handleSave(){
    
        let errorMessage: string = "campos inválidos: "

        if (!address.address1){
            errorMessage = errorMessage.concat("rua ")
            setError(errorMessage)
        }
        if (!address.address2){
            errorMessage = errorMessage.concat("bairro ")
            setError(errorMessage)
        }
        if (!address.city){
            errorMessage = errorMessage.concat("cidade ")
            setError(errorMessage)
        }
        if (!address.name){
            errorMessage = errorMessage.concat("nome ")
            setError(errorMessage)
        }
        if (!address.postalCode){
            errorMessage = errorMessage.concat("CEP ")
            setError(errorMessage)
        } 
        if (!address.state){
            errorMessage = errorMessage.concat("estado ")
            setError(errorMessage)
        }
        if (!address.unitNumber){
            errorMessage = errorMessage.concat("número ")
            setError(errorMessage)
        }
        else {
           
            apiCaller.post("/addresses/", {
                name: address.name,
                address1: address.address1,
                address2: address.address2,
                unitNumber: address.unitNumber,
                complement: address.complement,
                city: address.city,
                state: address.state,
                postalCode: parseInt(address.postalCode),
                default: addressContext?.addresses.length === 0? true: false
            }, {
                headers: {
                    "Authorization": `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {
                addressContext?.navigate("addressList")
            }).catch((error: AxiosError) => {
                console.log(error)
            });
                // }
            // })
            // .catch((error: AxiosError) => {
            //     console.log(error)
            // })
        }
    }

    function handleResetError(){
        setError("")
    }

    return (
        <div id="user-profile-address-new">
            <div id="user-profile-address-new-label">
                <p id="user-profile-address-new-label-txt">
                    Insira os dados do novo endereço
                </p>
            </div>
            <div id="user-profile-address-new-fields">
                <div className="user-profile-address-new-fields-container">
                    <div className="user-profile-address-new-fields">
                        <TextField
                            label="Nome"
                            onValueChange={(value: string) => {handleFieldChange("name", value)}}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div className="user-profile-address-new-fields">
                        <TextField
                            label="Endereço"
                            onValueChange={(value: string) => {handleFieldChange("address1", value)}}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div className="user-profile-address-new-fields">
                        <div className="user-profile-address-new-sub-fields">
                            <TextField
                                label="Número"
                                onValueChange={(value: number) => {handleFieldChange("unitNumber", value)}}
                                onSelected={handleResetError}
                            />
                        </div>
                        <div className="user-profile-address-new-sub-fields">
                            <TextField
                                label="Complemento"
                                onValueChange={(value: string) => {handleFieldChange("complement", value)}}
                                onSelected={handleResetError}
                            />
                        </div>
                    </div>
                    <div className="user-profile-address-new-fields">
                        <TextField
                            label="Bairro"
                            onValueChange={(value: number) => {handleFieldChange("address2", value)}}
                            onSelected={handleResetError}
                        />
                    </div>
                </div>
                <div className="user-profile-address-new-fields-container">
                    <div className="user-profile-address-new-fields">
                        {   
                            !stateFetchError &&
                            (
                                <Dropdown
                                    label="Estado"
                                    showOnClick={true}
                                    options={data.map(state => {return state.abbreviation})}
                                    onSelect={(value: string) => {handleFieldChange("state", value)}}
                                />
                            )
                        }
                        {
                            stateFetchError &&
                            (
                                <TextField
                                    label="Estado"
                                    onValueChange={(value: string) => {handleFieldChange("state", value)}}
                                    onSelected={handleResetError}
                                />
                            )
                        }
                    </div>
                    
                    <div className="user-profile-address-new-fields">
                    {   
                            !stateFetchError &&
                            (
                                <Dropdown
                                    label="Cidade"
                                    showOnClick={true}
                                    options={cityData.map(city => {return city.name})}
                                    onSelect={(value: string) => {handleFieldChange("city", value)}}
                                    default={address.city}
                                />
                            )
                        }
                        {
                            stateFetchError &&
                            (
                                <TextField
                                    label="Cidade"
                                    onValueChange={(value: string) => {handleFieldChange("city", value)}}
                                    onSelected={handleResetError}
                                />
                            )
                        }
                    </div>
                    <div className="user-profile-address-new-fields">
                        <TextField
                            label="CEP"
                            onValueChange={(value: string) => {handleFieldChange("postalCode", value)}}
                            onSelected={handleResetError}
                            maxCharacters={8}
                            numeric={true}
                        />
                    </div>
                    <div id="user-profile-address-new-btns-container">
                        <p id="user-profile-address-new-btns-save" onClick={handleSave}>
                            Salvar
                        </p>
                        <p id="user-profile-address-new-btns-cancel" onClick={handleCancel}>
                            Cancelar
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}