import React, { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { AuthContext } from "context/authProvider"
import NotFound from "pages/notFound/notFound"

export default function AdminProtectedRoute(children: any){
    const authContext = useContext(AuthContext)
    
    if (authContext?.isAuthenticated === true && authContext?.isAdministrator === true) {
        return <Outlet />
    } else {
        return <NotFound/>
    }
}
