import React, { useState, useContext } from "react"

import "./productDetails.css"
import "pure-react-carousel/dist/react-carousel.es.css";

import { CarouselProvider, Slider, Slide, ImageWithZoom } from "pure-react-carousel"
import { CartStorageContext } from "context/cartStorageProvider";
import { ProductPageContext } from "pages/productPage/productPage";
import { IProduct } from "interfaces/product/IProduct"
import VariationSelector from "./components/variationSelector/variationSelector"
import SizeSelector from "./components/sizeSelector/sizeSelector"
import PriceParser from "misc/priceParser"
import Freight from "./components/freight/freight";
import BagIcon from "assets/icons/bag-gray.png"
import { getProductImageUrl } from "misc/imageUrls";


export default function ProductDetails(props: {data: IProduct}){

    const productPageContext = useContext(ProductPageContext)
    const cartStorageContext = useContext(CartStorageContext)

    const [currentImage, setCurrentImage] = useState<number>(0)

    function handleMenuImageClick(index: number){
        setCurrentImage(index)
    }
    
    function handleAddToCart(goToCart: boolean){
        if (productPageContext) {
            if (productPageContext.product && productPageContext.variation && productPageContext.size) {
                let productCart: IProduct = {
                    id: productPageContext.product.id,
                    name: productPageContext.product.name,
                    url: productPageContext.product.url,
                    featured: productPageContext.product.featured,
                    enabled: productPageContext.product.enabled,
                    categoryId: productPageContext.product.categoryId,
                    subcategoryId: productPageContext.product.subcategoryId,
                    description: productPageContext.product.description,
                    variations: [
                        {
                            id: productPageContext.variation.id,
                            name: productPageContext.variation.name,
                            enabled: productPageContext.variation.enabled,
                            color: productPageContext.variation.color,
                            price: productPageContext.variation.price,
                            promotionEnabled: productPageContext.variation.promotionEnabled,
                            promotionPrice: productPageContext.variation.promotionPrice,
                            sizes: [
                                {
                                    id: productPageContext.size.id,
                                    size: productPageContext.size.size,
                                    quantity: productPageContext.size.quantity,
                                    enabled: productPageContext.size.enabled
                                }
                            ],
                            images:[
                                {
                                    filename: productPageContext.variation.images[0].filename
                                }
                            ] 
                        }

                    ]
                    
                    
                }

                cartStorageContext?.addProduct(productCart)
                productPageContext.setRunAnimation(true)
                
                if (goToCart){
                    productPageContext.setBuyMode("now")
                }
            }
        }
    }

    return (
        <div id="product-page-body-details-main">
            <div id="product-page-body-details-image-slider">
                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={125}
                    totalSlides={productPageContext?.variation?.images.length || 0}
                    infinite={true}
                    dragEnabled={false}
                    className="product-page-body-details-image-slider"
                    currentSlide={currentImage}
                >   
                    <ul id="product-page-body-details-image-slider-list">
                        {
                            productPageContext?.variation?.images.map((img, index) => {
                                return (
                                    <li 
                                        key={index}
                                        className="product-page-body-details-image-slider-list-element" 
                                        onClick={() => {handleMenuImageClick(index)}}
                                    >
                                        <img 
                                            id="product-page-body-details-image-slider-list-element-img"
                                            src={getProductImageUrl(img.filename)} 
                                            alt="" 
                                            draggable={false}
                                        />
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <Slider className="product-page-body-details-image-slider-focused">
                        {
                            productPageContext?.variation?.images.map((img, index) => {
                                return (
                                    <Slide 
                                        index={index}
                                        key={index}
                                    >
                                        <ImageWithZoom 
                                            className="product-page-body-details-image-slider-focused-img" 
                                            src={getProductImageUrl(img.filename)}
                                        />
                                    </Slide>
                                )
                            })
                        }
                    </Slider>
                </CarouselProvider>
            </div>
            <div id="product-page-body-details-info">
                <div id="product-page-body-details-info-name">
                    <p id="product-page-body-details-info-name-txt">
                        {
                            `${productPageContext?.product.name} ${productPageContext?.variation.name}`
                        }
                    </p> 
                </div>
                <div id="product2-details-code">
                    <p id="product2-details-code-txt">
                        {`Estoque: ${productPageContext?.size.quantity}`}
                    </p>
                </div>
                {
                    !productPageContext?.variation?.promotionEnabled &&
                    (
                        <div id="product2-details-price">
                            
                            <p id="product2-details-price-txt">
                                {`R$ ${PriceParser.parse(productPageContext?.variation?.price || 0)}`}
                            </p>
                        </div>
                    )
                }
                {
                    productPageContext?.variation?.promotionEnabled &&
                    (
                        <div id="product2-details-price-promotion">
                            <p id="product2-details-price-promotion-new-txt">
                                {`R$ ${PriceParser.parse(productPageContext?.variation?.promotionPrice)}`}
                            </p>
                            <p id="product2-details-price-promotion-txt">
                                {`R$ ${PriceParser.parse(productPageContext?.variation?.price)}`}
                            </p>
                        </div>
                    )
                }
                <div id="product2-details-option-container">
                    <div id="product-2-details-option-selector">
                        <p className="product2-details-label-txt">
                            Variação
                        </p>
                        {
                            props.data.variations &&
                            ( 
                                <VariationSelector
                                    data={props.data.variations}
                                />
                            )
                        }
                    </div>
                    <div id="product-2-details-option-stock">

                    </div>
                </div>
                <div id="product2-details-size-container">
                    <p className="product2-details-label-txt">
                        Tamanho
                    </p>
                    {
                        productPageContext?.variation?.sizes &&
                        (
                            <SizeSelector 
                                sizes={productPageContext.variation.sizes}
                            />
                        )
                    }
                </div>
                <div id="product2-details-freight-container">
                    <Freight/>
                </div>
                <div id="product2-details-btns-container">
                    <div 
                        id="product2-details-btn-buy"
                        onClick={() => {handleAddToCart(true)}}
                    >
                        <p id="product2-details-btn-buy-txt">
                            Comprar agora
                        </p>
                    </div>
                    <div 
                        id="product2-details-btn-add" 
                        onClick={() => {handleAddToCart(false)}}
                    >
                        <img 
                            id="product2-details-btn-add-img"
                            src={BagIcon} 
                            alt="" 
                            draggable={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}