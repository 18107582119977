import React, { useState } from "react";

import "./freight.css";
import calculaFrete from "api/calculaFrete";

export default function Freight() {
    const [cep, setCEP] = useState<string>("");
    const [preco, setPreco] = useState<number>();

    function handleCepChange(event: any) {
        // Remove todos os caracteres que não são dígitos
        let rawValue = event.target.value.replace(/\D/g, "");

        // Formata o CEP como XXXXX-XXX se tiver pelo menos 6 dígitos
        if (rawValue.length > 5) {
            rawValue = rawValue.replace(/^(\d{5})(\d)/, "$1-$2");
        }

        setCEP(rawValue);
    }

    async function calcularFrete() {
        const CEP = cep.replace("-", "");
        setPreco(await calculaFrete(CEP));
    }

    return (
        <div id="product-details-freight-main">
            <div id="product-details-freight-container">
                <p id="product-details-freight-label">CEP</p>
                <div id="product-details-freight-input-container">
                    <input
                        placeholder="CEP"
                        id="product-details-freight-input"
                        type="text"
                        value={cep}
                        onChange={handleCepChange}
                        maxLength={9} // Limite para o formato XXXXX-XXX
                    />
                    <div
                        id="product-detail-freight-btn-container"
                        onClick={calcularFrete}
                    >
                        <p id="product-detail-freight-btn">Calcular</p>
                    </div>
                    { 
                        preco &&
                        <div id="product-detail-freight-result">
                            <p id="product-detail-freight-result-text">
                                R$ { preco.toFixed(2).replace(".", ",") }
                            </p>
                        </div>
                    }
            </div>
                
            </div>
        </div>
    );
}
