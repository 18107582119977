import React, {useState, useEffect, Fragment} from "react"

import "./catalog.css"
import "pure-react-carousel/dist/react-carousel.es.css";

import Header       from "components/header/header"
import Footer       from "components/footer/footer"
import ProductCard  from "components/productCard/productCard"
import CategoryCard from "./components/categoryCard/categoryCard"
import { CarouselProvider, Slider, Slide, Image  } from "pure-react-carousel"
import { apiCaller } from "api/apiCaller";
import { IProduct } from "interfaces/product/IProduct"
import { AxiosError, AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import IBanner from "interfaces/IBanner";
import FullscreenLoading from "components/fullscreenLoading/fullscreenLoading";
import ContentPagination from "components/contentPagination/contentPagination";
import { getBannerImageUrl } from "misc/imageUrls";

const maxItems = 10

export default function Catalog(){

    const [carouselHeight, setCarouslHeight] = useState<number>(500)
    const [products,       setProducts     ] = useState<IProduct[]>([])
    const [banners,        setBanners      ] = useState<IBanner[]>([])
    const [isLoadingBanners, setIsLoadingBanners] = useState<boolean>(true)
    const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(true)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [maxPages, setMaxPages] = useState<number>(1)

    useEffect(() => {
        console.log(currentPage)
        /* 
            disable the scroll while the loading animation is running
            to better understanding see FullscreenLoading component
        */

        apiCaller.get(`/products/count?featured=${0}`, {

        }).then((response: AxiosResponse) => {
            console.log(Math.ceil(response.data.data / maxItems))
            setMaxPages(Math.ceil(response.data.data / maxItems))
        })

        apiCaller.get("/banners")
            .then((response: AxiosResponse) => {
                setBanners(response.data.data)
            }).catch((error: AxiosError) => {
                
            }).finally(() => {
                setTimeout(() => {
                    setIsLoadingBanners(false)
                    document.documentElement.style.overflow = "auto";
                }, 500)
            })

        apiCaller.post(`/products/find?page=${currentPage}&per_page=${maxItems}`, {
            featured: false,
            enabled: true
        }).then((response: AxiosResponse) => {

            const sumWithInitial = response.data.data.reduce((accumulator: any, currentValue: any) => accumulator + currentValue,
                0,
            );

            setProducts(response.data.data)
        }).catch((error: AxiosError) => {

        }).finally(() => {
            setTimeout(() => {
                setIsLoadingProducts(false)
                document.documentElement.style.overflow = "auto";
            }, 500)
        })
    }, [currentPage])

    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")

            if (rootElement) {
                const rootWidth = rootElement.getBoundingClientRect().width
                if (rootWidth <= 500){
                    setCarouslHeight(1200)
                }
                else if (rootWidth <= 900){
                    setCarouslHeight(1000)
                }
                else {
                    setCarouslHeight(500)
                }
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    function setScreenWidth(){
        return window.screen.availWidth
    }

    function handlePageChange(page: number){
        console.log("pagina:", page)
        setCurrentPage(page)
    }

    return (
        <Fragment>
            {
                isLoadingBanners && isLoadingProducts &&
                (
                    <FullscreenLoading/>
                )
            }
            <div id="catalog-main" >
                <Header/>
                <div id="catalog-content">
                    <div id="catalog-content-highlights-container">
                        <CarouselProvider
                            naturalSlideWidth={1920}
                            naturalSlideHeight={carouselHeight}
                            totalSlides={banners.length || 1}
                            dragEnabled={true}
                            isPlaying={true}
                            className="catalog-content-highlights-carousel"
                        >
                            <Slider>
                                {
                                    banners.map((banner: IBanner, index: number) => {
                                        return (
                                            <Slide key={banner.id} index={index}>
                                                <Link to={banner.url} draggable={false}>
                                                    <Image 
                                                        className="slider-offset"
                                                        hasMasterSpinner={true} 
                                                        src={getBannerImageUrl(banner.filename)}
                                                        draggable={false}
                                                    />
                                                </Link>
                                            </Slide>
                                        )
                                    })
                                }
                            </Slider>
                        </CarouselProvider>
                        <div id="catalog-content-highlights-loader">

                        </div>
                    </div>
                    <ul id="catalog-content-categories">
                        {/* <CategoryCard/>
                        <CategoryCard/>
                        <CategoryCard/> */}
                    </ul>
                    <ul id="catalog-content-products">
                        {
                            products.map((product) => (
                                product.variations.map((variation) => {
                                    return (
                                        <ProductCard
                                            key={product.id + variation.id}
                                            data={{
                                                id: product.id + variation.id,
                                                name: product.name,
                                                url: product.url,
                                                description: product.description,
                                                enabled: product.enabled,
                                                featured: product.featured,
                                                categoryId: product.categoryId,
                                                subcategoryId: product.subcategoryId,
                                                variations: [variation]
                                            }}
                                        />
                                    )
                                })
                            ))
                        }
                    </ul>
                    <ContentPagination maxPages={maxPages} onPageChange={handlePageChange} />
                </div>
                <Footer/>
            </div>
        </Fragment>
    )
}