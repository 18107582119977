import React, {useState, useEffect, createContext, Dispatch, useContext} from "react"
import { useNavigate } from "react-router-dom"

import "./product.css"

import CheckBox          from "components/inputs/checkbox/checkbox"
import Header            from "./components/header/header"
import Dropdown          from "components/inputs/dropdown/dropdown"
import TextFieldLarge from "components/inputs/textFieldLarge/textFieldLarge"
import Variation         from "./components/variation/variation"
import Navigation        from "./components/navigation/navigation"
import AdminPageTemplate from "pages/admin/components/adminPageTemplate/adminPageTemplate"
import { apiCaller } from "api/apiCaller"
import { AuthContext } from "context/authProvider"
import { IProduct, ICategory, ISubcategory} from "interfaces/product/IProduct"
import { AxiosError, AxiosResponse } from "axios"
import { useLocation } from "react-router-dom"

interface IProductContext {
    variationIndex: number
    setVariationIndex: Dispatch<React.SetStateAction<number>>
	product: IProduct
	setProduct: Dispatch<React.SetStateAction<IProduct>>
    error: string
    setError: Dispatch<React.SetStateAction<string>>
}

export const ProductContext = createContext<IProductContext | undefined>(undefined);

export default function AdminProduct(){

    const authContext = useContext(AuthContext)
    const navigate = useNavigate()
    const location = useLocation()

    const [disableCheckbox,        setDisableCheckbox       ] = useState<boolean>(true)
    const [error,                  setError                 ] = useState<string>("")
    const [categories,             setCategories            ] = useState<ICategory[]>([])
    const [specialCategories,      setSpecialCategories     ] = useState<string[]>([])
    const [selectedCategory,       setSelectedCategory      ] = useState<ICategory>()
    const [selectedVariationIndex, setSelectedVariationIndex] = useState<number>(0)
    const [isNewProduct,           setIsNewProduct          ] = useState<boolean>(true)

    useEffect(() => {
        console.log(">>", selectedVariationIndex)
        console.log(">>", product.variations[selectedVariationIndex])
    }, [selectedVariationIndex])

    const [product, setProduct] = useState<IProduct>(
        {
            id: "",
            name: "",
            url: "",
            enabled: false,
            featured: false,
            categoryId: "",
            subcategoryId: "",
            variations: [],
            description: ""
        }
    )

    useEffect(() => {

        /*
            REFATORAR PARA UTILIZAR O GLOBAL CONTEXT PARA BUSCAR AS CATEGORIAS
        */

        apiCaller.get("/categories/")
        .then((responseCategory: AxiosResponse) => {
            setCategories(responseCategory.data.data)

            if (location.state){
                apiCaller.post("/products/find-ids", {
                    ids: [location.state.id]
                })
                .then((response: AxiosResponse) => {
                    setProduct(response.data.data[0])
                    let aux = responseCategory.data.data.find((category: ICategory) => {
                        if (category.id === response.data.data[0].categoryId){
                            return category
                        }
                    })
    
                    setSelectedCategory(aux)
                    setIsNewProduct(false)
                })
                .catch((error: AxiosError) => {
                    
                })
            }

        })
        .catch((error: AxiosError) => {

        })

    },[])

    useEffect(() => {
        let haveEnabledVariation = false

        product.variations.forEach((variation) => {
            if (variation.enabled){
                haveEnabledVariation = true
            }
        })

        if (product.name === ""){
            handleProductEnable(false)
            setDisableCheckbox(true)
        }
        else if (haveEnabledVariation === false){
            handleProductEnable(false)
            setDisableCheckbox(true)
        } 
        else if (product.categoryId === "") {
            handleProductEnable(false)
            setDisableCheckbox(true)
        }
        else if (product.subcategoryId === ""){
            handleProductEnable(false)
            setDisableCheckbox(true)
        } else {
            setDisableCheckbox(false)
        }
    },[product.categoryId, product.name, product.subcategoryId, product.variations])

    function handleChange(field: string, value: string | boolean | number){
        if (field === "category" && typeof value === "string"){

            let aux = categories.find((category: ICategory) => {
                if (category.name === value){
                    return category
                }
            })

            if (aux) {
                let id = aux.id
                setProduct((prev: IProduct) => ({
                    ...prev,
                    categoryId: id,
                    subcategoryId: ""
                }))

                setSelectedCategory({
                    id: aux.id,
                    name: aux.name,
                    subcategories: aux.subcategories,
                    cover_id: aux.cover_id
                })
            }
        }

        if (field === "subcategory" && typeof value === "string"){
            let aux = selectedCategory?.subcategories.find((subcategory: ISubcategory) => {
                if (subcategory.name === value){
                    return subcategory
                }
            })

            if (aux) {
                let id = aux.id
                setProduct((prev: IProduct) => ({
                    ...prev,
                    subcategoryId: id
                }))
            }
        }

        else {
            setProduct((prev) => {
                return {
                    ...prev,
                    [field]: value
                };
            });
        }
    }

    function handleSave(){
        if (isNewProduct){
            apiCaller.post('/products/bulk', {
                name: product.name,
                enabled: product.enabled,
                featured: product.featured,
                categoryId: product.categoryId,
                subcategoryId: product.subcategoryId,
                variations: product.variations,
                specialCategoryId: null,
                description: product.description
            }, {
                headers: {
                    "Authorization": `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {
                // handleCancel()
    
            }).catch((error: AxiosError) => {
                // handleCancel()
            });
        } else {
            apiCaller.put(`/products/diff/${product.id}`, {
                name: product.name,
                url: product.url,
                enabled: product.enabled,
                featured: product.featured,
                categoryId: product.categoryId,
                subcategoryId: product.subcategoryId,
                variations: product.variations,
                specialCategoryId: null,
                description: product.description
            }, {
                headers: {
                    "Authorization": `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {
                // handleCancel()
    
            }).catch((error: AxiosError) => {
                // handleCancel()
            });
        }


        navigate("/admin/produtos")
    }

    function handleProductEnable(value: boolean){
        setProduct((prev) => ({
            ...prev,
            enabled: value
        }))
    }

    function parseCategory(id: string, subcategory: boolean){
        let aux
        if (!subcategory) {
            aux = categories.find((category: ICategory) => {
                if (category.id === id){
                    return category
                }
            })
        } else {
            aux = selectedCategory?.subcategories.find((subcategory: ISubcategory) => {
                if (subcategory.id === id){
                    return subcategory
                }
            })
        }
        if (aux){
            return aux.name
        } else {
            return ""
        }
    }

    function handleCheckboxClick(){
        if (disableCheckbox){
            setError("Erro: para habilitar o produto preencha todos os campos")
        }
    }

    return (
        <AdminPageTemplate navigationUrl="/admin/produtos/produto">
            <ProductContext.Provider 
                value={
                    {
                        product: product, 
                        setProduct: setProduct, 
                        variationIndex: selectedVariationIndex, 
                        setVariationIndex: setSelectedVariationIndex,
                        error: error,
                        setError: setError
                    }
                }>
                <div id="admin-product-main">
                    <Header
                        onSave={handleSave}
                    />
                    <div id="admin-product-body">
                        <div id="admin-product-primary">
                            <div className="admin-product-primary-field">
                                <Dropdown
                                    label="Categoria principal"
                                    showOnClick={true}
                                    options={categories.map((option) => {return option.name})}
                                    onSelect={(value: string) => {handleChange("category", value)}}
                                    default={parseCategory(product.categoryId, false)}
                                />
                            </div>
                            <div className="admin-product-primary-field">
                                {
                                    selectedCategory &&
                                    (
                                        <Dropdown
                                            label="Categoria secundária"
                                            showOnClick={true}
                                            options={selectedCategory.subcategories.map(option => {return option.name})}
                                            onSelect={(value: string) => {handleChange("subcategory", value)}}
                                            default={parseCategory(product.subcategoryId, true)}
                                        />
                                    )
                                }
                            </div>
                            <div className="admin-product-primary-field">
                                <Dropdown
                                    label="Categoria especial"
                                    hideOnClick={true}
                                    showOnClick={true}
                                    options={specialCategories}
                                    onSelect={() => {}}
                                    default={specialCategories[0]}
                                />
                            </div>
                            <div id="admin-product-primary-description">
                                <TextFieldLarge
                                    label="Descrição"
                                    disabled={false}
                                    onValueChange={(value: string) => {handleChange("description", value)}}
                                    value={product.description}
                                />
                            </div>
                            <div id="admin-product-primary-checkbox-field1">
                                <CheckBox
                                    text="Habilitar Produto"
                                    disabled={disableCheckbox}
                                    value={product.enabled}
                                    onValueChange={(value: boolean) => {handleChange("enabled", value)}}
                                    onClick={handleCheckboxClick}
                                />
                            </div>
                            <div id="admin-product-primary-checkbox-field2">
                                <CheckBox
                                    text="Destacar"
                                    onValueChange={(value: boolean) => {handleChange("featured", value)}}
                                    value={product.featured}
                                />
                            </div>
                        </div>
                        <div id="admin-product-variation">
                            <Navigation
                                data={product.variations}
                            />
                            {
                                product.variations[selectedVariationIndex] &&
                                (
                                    <Variation
                                        data={product.variations[selectedVariationIndex]}
                                    />
                                )
                            }
                            {
                                !product.variations[selectedVariationIndex] &&
                                (
                                    <div id="admin-product-variation-placeholder">
                                        <p id="admin-product-variation-placeholder-txt">
                                            Crie uma variação no menu acima
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </ProductContext.Provider>
        </AdminPageTemplate>
    )
}