import React, {useState, useEffect, useContext} from "react"

import "./support.css"

import Header from "components/header/header"
import Footer from "components/footer/footer"

export default function Support(){

    const [screenHeight, setScreenHeight] = useState<number>(0)

    useEffect(() => {
        const headerElement = window.document.getElementById("header-main")
        const productCategoryHeader = window.document.getElementById("support-template-header")
        
        if (headerElement && productCategoryHeader){
            const headerHeight = headerElement.getBoundingClientRect().height
            const pageTemplateHeaderHeight = productCategoryHeader.getBoundingClientRect().height

            let height = window.screen.availHeight
            height = height - (window.outerHeight - window.innerHeight)
            height = height - headerHeight
            height = height - pageTemplateHeaderHeight
            setScreenHeight(height)
        }
    },[window.innerHeight])


  
    return (
        <>  
            <Header/>
            <div id="support-template-main">
                <div id="support-template-content">
                    <div id="support-template-header">
                        <p id="support-header-txt">
                            Suporte
                        </p>
                    </div>  
                    <div id="support-template-list" style={{minHeight: screenHeight}}>
                        <p className="support-text support-space support-text-category">
                            Estamos sempre à disposição para atender você e tirar qualquer dúvida! Entre em contato conosco pelos nossos canais:
                        </p>
                        <p className="support-text support-text-category">
                            Instagram
                        </p>
                        <a className="support-text support-space" href="https://www.instagram.com/ebahmodainfantil/">
                            @ebahmodainfantil
                        </a>
                        <p className="support-text support-text-category">
                            WhatsApp
                        </p>
                        <p className="support-text support-space">
                            (31) 99836-2607
                        </p>
                        <p className="support-text support-text-category">
                            E-mail
                        </p>
                        <p className="support-text support-space">
                            suporte@ebahmodainfantil.com.br
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}