import React, {useState, useEffect, useContext} from "react"
import { Link } from "react-router-dom"

import "./header.css"

import SearchField from "components/searchField/searchField"
import Dropdown from "components/inputs/dropdownWithoutLabel/dropdownWithoutLabel"
import { GlobalContext } from "context/globalProvider"

export default function Header(props: {onSearch: Function}){

    const globalContext = useContext(GlobalContext)

    const [searchCategory, setSearchCategory] = useState<string>("")
    const [categories, setCategories] = useState<string[]>([])
    const [searchString, setSearchString] = useState<string>("")

    useEffect(() => {

        const categories = globalContext?.categories.map((category) => {
            return category.name
        })

        categories?.push("Todos")
        if (categories){
            setCategories(categories)
        }

        setSearchCategory("Todos")
    },[globalContext?.categories])

    function handleChangeSearchString(value: string){
        setSearchString(value)
        props.onSearch(value)
    }

    return (
        <div id="admin-product-list-header-main">
            <div id="admin-product-list-header-searchfield">
                <div id="admin-product-list-header-searchfield-main">
                    <input 
                        id="admin-product-list-header-searchfield-text-field" 
                        placeholder={"Insira o nome do produto"} 
                        onChange={(e) => {handleChangeSearchString(e.target.value)}}
                        value={searchString}    
                    />
                </div>
            </div>
            <div id="admin-product-list-header-new-product">
                <Link id="admin-product-list-header-new-product-btn" to={"/admin/produtos/novo"}>
                    Novo Produto
                </Link>
            </div>
        </div>
    )
}