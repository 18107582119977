import React, {useState, useEffect, useContext} from "react"

import "./informative.css"

import Header from "components/header/header"
import Footer from "components/footer/footer"

export default function Informative(){

    const [screenHeight, setScreenHeight] = useState<number>(0)

    useEffect(() => {
        const headerElement = window.document.getElementById("header-main")
        const productCategoryHeader = window.document.getElementById("informative-template-header")
        
        if (headerElement && productCategoryHeader){
            const headerHeight = headerElement.getBoundingClientRect().height
            const pageTemplateHeaderHeight = productCategoryHeader.getBoundingClientRect().height

            let height = window.screen.availHeight
            height = height - (window.outerHeight - window.innerHeight)
            height = height - headerHeight
            height = height - pageTemplateHeaderHeight
            setScreenHeight(height)
        }
    },[window.innerHeight])


  
    return (
        <>  
            <Header/>
            <div id="informative-template-main">
                <div id="informative-template-content">
                    <div id="informative-template-header">
                        <p id="informative-header-txt">
                            Quem somos
                        </p>
                        <p id="informative-subheader-txt">
                            
                        </p>
                    </div>  
                    <div id="informative-template-list" style={{minHeight: screenHeight}}>
                        <p className="informative-text">
                            A Ebah! Moda Infantil é uma loja online criada com o carinho e a visão de uma mãe apaixonada por vestir crianças como crianças. Fundada por Jessica Reis, mãe de três meninas – Eva, e as gêmeas Ana e Bia – a loja nasceu em Florestal, Minas Gerais, e está no mercado há quase dois anos.
                        </p>
                        <p className="informative-text">
                            Nossa missão é levar conforto, alegria e estilo para as crianças, oferecendo roupas coloridas, com estampas divertidas e alegres, sempre pensando no que elas realmente gostam de usar. Acreditamos que a infância deve ser vivida de forma leve e descontraída, e isso se reflete em cada peça que selecionamos com cuidado.
                        </p>
                        <p className="informative-text">
                            Atendemos meninos e meninas nos tamanhos de 1 a 10, e já temos o orgulho de colorir guarda-roupas em mais de 20 cidades mineiras e três estados brasileiros.
                        </p>
                        <p className="informative-text">
                        Nosso slogan, "Ebah! Divertida como a infância", resume bem o que somos: uma marca que celebra a leveza, a alegria e a liberdade de ser criança.
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}